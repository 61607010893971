<template>
  <!-- Main content -->
  <section class="content classes">
    <!--Class Modal-->
    <modal title="Add Class" v-if="modal.isVisible('add-group')" @close="modal.hide('add-group')" :show-footer="false">
      <ValidationObserver v-slot="{ passes }">
        <form ref="observer" class="ui form class-form form-horizontal" @submit.prevent="passes(createGroup)">
          <ValidationProvider :rules="{ required: true }" name="Name" v-slot="{ errors }">
          <div class="form-group" :class="{ 'has-error':  errors[0] }">
            <label for="name" class="col-sm-2 control-label">Name*</label>
            <div class="col-sm-10">
              <div class="input-group">
                <input class="form-control" name="name" placeholder="Name" type="text" v-model="newGroup.name" id="name">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
          </ValidationProvider>
          <div class="form-group">
            <div class="col-sm-offset-10 col-sm-2 text-right">
              <loading-button :is-loading="isLoading">Save</loading-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </modal>
    <!--Delete Class Modal-->
    <modal title="Delete Class" v-if="modal.isVisible('delete-group')" @close="modal.hide('delete-group')" id="delete-modal">
      <h3 class="text-center">Delete "{{currentGroup.name}}" from {{school.name}}?</h3>
      <p class="text-center">Deleting a class cannot be reversed. Any pupils of this class will not be deleted.</p>
      <hr>
      <div class="button-locator">
        <button class="btn btn-yes" @click="deleteGroup">Yes</button>
        <button class="btn btn-no" @click="keepGroup">No</button>
      </div>

    </modal>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 box-tools">
        <div class="pull-right">
          <loading-button @click="modal.show('add-group')" :is-loading="isLoading">Add Class</loading-button>

        </div>
      </div>
    </div>
    <div class="row center-block">
        <div class="box box-info">
          <!-- /.box-header -->
          <div class="box-body">
            <vue-good-table
              styleClass="vgt-table striped dataTable"
              theme="auris-admin"
              :columns="columns"
              :rows="groups"
              :search-options="{
                enabled: true,
                trigger: 'keyup',
                skipDiacritics: true,
                placeholder: 'Search',
              }"
              @on-per-page-change="handlePerPageChange($event)"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPage: parseInt(rowsPerPage),
                position: 'bottom',
                perPageDropdown: rowsPerPageOptions,
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
              }"
            >
              <template slot="table-row" slot-scope="props">
                <a class="u-hover__pointer" v-if="props.column.field == 'name'" @click="goToClassPage(props.row.id)">
                  {{props.formattedRow[props.column.field]}}
                </a>
                <span v-else-if="props.column.field == 'actions'">
                  <button class="btn btn-link btn-icon-book" @click.prevent="$router.push('/classes/' + props.row.id + '/books')" v-if-authorized="{ action: 'manage_books', params: { group: props.row} }">
                    <i class="icon icon-book"></i> Manage books
                  </button>
                  <button class="btn btn-link" @click.prevent="$router.push('/classes/' + props.row.id)">
                      <i class="icon icon-arrow-circle-right"></i> Manage class
                  </button>
                  <button class="btn btn-link btn-icon-trash" @click.prevent="confirmDeleteGroup(props.row.id, props.row.name)">
                    <i class="icon icon-trash"></i> Delete
                  </button>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
          <loading-state :is-loading="isLoading"></loading-state>
        </div>
    </div>

  </section>
  <!-- /.content -->
</template>

<script>
import ModalJS from '@/components/utils/Modal';
import LoadingButton from '@/components/utils/LoadingButton';
import LoadingState from '@/components/utils/LoadingState';
import checks from '@/plugins/Authorization/permissions_checks';
import CurrentUserMixins from '@/components/mixins/CurrentUserMixins';
import ClassesMixin from '@/components/mixins/ClassesMixin';
import Modal from '@/components/classes/Modal';
import { VueGoodTable } from 'vue-good-table';
import StringUtils from '@/components/mixins/StringUtils';
import TableMixin from '@/components/mixins/TableMixin';

export default {
  data() {
    return {
      groups: [],
      originalGroups: [],
      showAllGroups: true,
      response: 'Data Loading@/components.',
      modal: new Modal({
        addGroup: false,
        deleteGroup: false,
      }),
      currentGroup: { name: null, id: null },
      toggleClass: 'fa-toggle-off',
      newGroup: {
        name: '',
      },
      isLoading: false,
      school: null,
      isSchoolAdmin: checks.isSchoolAdmin(this.$store),
      columns: [
        {
          label: 'Name',
          field: 'name',
          sortable: true,
          firstSortType: 'desc',
        },
        {
          label: 'Pupil Count',
          field: 'readers_count',
          sortable: true,
          formatFn: this.formatPupils,
        },
        {
          label: 'Assigned Books',
          field: 'assigned_book_count',
          sortable: true,
          formatFn: this.formatBooks,
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          tdClass: 'actions',
        },
      ],
    };
  },
  mixins: [CurrentUserMixins, ClassesMixin, StringUtils, TableMixin],
  mounted() {
    this.$nextTick(() => {
      this.school = this.getUserSchool();
      this.getGroupsData();
    });
  },
  methods: {
    goToClassPage(id) {
      this.$router.push(`/classes/${id}`);
    },
    formatPupils(count) {
      if (count < 1) {
        return 'None';
      }
      return `${count} ${this.pluralize(count, 'pupil')}`;
    },
    formatBooks(count) {
      if (count < 1) {
        return 'None';
      }
      return `${count} ${this.pluralize(count, 'book')}`;
    },
    getGroupsData() {
      this.isLoading = true;
      this.getGroups(this.school.id)
        .then((groups) => {
          this.groups = groups;
          this.originalGroups = groups;
          this.isLoading = false;
        });
    },
    toggleGroups(showAll) {
      this.showAllGroups = this.showAllGroups === false;
      this.groups = this.originalGroups;
      if (!showAll) {
        this.groups = this.groups.filter((item) => item.user_manages);
      }
      if (showAll === true) {
        this.toggleClass = 'fa-toggle-off';
      } else if (showAll === false) {
        this.toggleClass = 'fa-toggle-on';
      }
    },
    createGroup() {
      this.isLoading = true;
      this.$http.post(`enterprises/${this.school.id}/groups`, {
        name: this.newGroup.name,
        assign_self: true,
      })
        .then((response) => {
          this.isLoading = false;
          this.modal.hide('add-group');
          this.$apiResponse.renderSuccessMessage(response, 'The class has been successfully created');
          this.getGroupsData();
          this.newGroup.name = '';
        })
        .catch((error) => {
          this.isLoading = false;
          this.modal.hide('add-group');
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    confirmDeleteGroup(id, name) {
      this.modal.show('delete-group');
      this.currentGroup = { id, name };
    },
    deleteGroup() {
      if (this.currentGroup.id !== null) {
        this.$http.delete(`enterprises/${this.school.id}/groups/${this.currentGroup.id}`)
          .then((response) => {
            this.$apiResponse.renderSuccessMessage(response, 'The class has been successfully deleted');
            this.getGroupsData();
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
          });
        this.modal.hide('delete-group');
      }
    },
    keepGroup() {
      this.modal.hide('delete-group');
      this.currentGroup = { name: null, id: null };
    },
  },
  components: {
    LoadingButton,
    LoadingState,
    modal: ModalJS,
    'loading-button': LoadingButton,
    VueGoodTable,
  },
};
</script>
<style scoped lang="scss">
  .table-responsive {
    border-width: 0;
  }
  .form-group.checkbox {
     margin-left:0;
    margin-bottom: 20px;
   }
  .form-group.checkbox input {
    margin: 0;
    top: 11px;
  }

  #classes button.text-danger {
    color: #a94442;
  }
  section.classes .box-header {
    margin-bottom: 10px;
  }
  .btn-box-tool {
    font-size: 16px;
  }
  .btn-box-tool:focus {
    outline: none;
  }
  #classes h3 span {
    cursor: pointer;
  }
  .button-locator {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn.btn-yes, .btn.btn-no {
    color: #fff;
  }
</style>
