export default {
  methods: {
    handlePerPageChange(e) {
      const { currentPerPage } = e;
      this.$store.dispatch('setRowsPerPage', currentPerPage);
    },
  },
  computed: {
    rowsPerPage() {
      return this.$store.state.rowsPerPage;
    },
  },
  data() {
    return {
      rowsPerPageOptions: [10, 25, 50, 100, 200],
    };
  },
};
