<template>
  <div></div>
</template>

<script>
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import config from '@/config';

export default {
  name: 'login-as',
  mixins: [CurrentUserMixin],
  mounted() {
    const re = new RegExp(config.adminPanelURL);
    if (!re.test(document.referrer)) {
      this.$router.push('/logout');
      return;
    }
    this.$store.dispatch('setToken', this.$route.params.token)
      .then(() => {
        this.getMe()
          .then(() => {
            this.$router.push('/').catch(() => {});
          });
      });
  },
};
</script>
