import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';

export default {
  data() {
    return {};
  },
  mixins: [SubscriptionMixin, CurrentUserMixin],
  methods: {
    emptyStore() {
      window.localStorage.removeItem('invite');
      window.localStorage.removeItem('invitee_email');
    },
    decline(inviteId = null) {
      this.isLoading = true;
      let url = `enterprises/invites/${this.$route.params.id}/decline`;
      if (inviteId) {
        url = `enterprises/invites/${inviteId}/decline`;
      }
      this.$http.post(url, [])
        .then(() => {
          this.emptyStore();
          this.isLoading = false;
          this.$store.dispatch('logout');
          this.$router.replace({ path: '/login', query: { action: 'decline' } });
        })
        .catch((error) => {
          this.isLoading = false;
          this.emptyStore();
          this.currentStep = null;
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    getInvites(entId) {
      return new Promise((resolve, reject) => {
        this.$http.get(`enterprises/${entId}/invites`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    handleInviteProcess(inviteId) {
      return new Promise((resolve, reject) => {
        this.$http.post(`enterprises/invites/${inviteId}/accept`, [])
          .then(() => this.getMe())
          .then(() => this.handleSubscriptionProcess())
          .then((path) => {
            this.emptyStore();
            resolve(path);
          })
          .catch((error) => {
            this.emptyStore();
            reject(error);
          });
      });
    },
  },
};
