<template>
  <div>
      <p class="text-center">We recommend keeping books allocated to build your pupils book bag. Adding and removing the same book over time can make the stats you see less informative.</p>
      <p class="text-center">Do you wish to proceed?</p>
      <div class="text-center">
        <button @click="yes()" class="btn btn-lg btn-yes btn-primary">Yes</button>
        <button @click="no()" class="btn btn-no btn-lg btn-primary">No</button>
      </div>
  </div>
</template>
<script>
export default {
  methods: {
    yes() {
      this.$emit('yes');
    },
    no() {
      this.$emit('no');
    },
  },
};
</script>
