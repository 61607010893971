function getElementDisplayStatus(action, params, node) {
  return node.context.$authorization.checkAuthorization(action, params) ? null : 'none';
}

export default {
  bind(el, binding, vnode) {
    if (el) {
      el.style.display = getElementDisplayStatus(binding.value.action, binding.value.params, vnode);
    }
  },
  update(el, binding, vnode) {
    if (el) {
      el.style.display = getElementDisplayStatus(binding.value.action, binding.value.params, vnode);
    }
  },
  componentUpdated(el, binding, vnode) {
    if (el) {
      el.style.display = getElementDisplayStatus(binding.value.action, binding.value.params, vnode);
    }
  },
};
