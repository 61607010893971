<template>
  <!-- Main content -->
  <section class="content">
    <!-- Modals Start -->
    <modal title="Delete teacher" v-if="modal.isVisible('deleteTeacher')" @close="handleCloseModal('deleteTeacher')">
      <h3 class="text-center">Remove {{teacherToDelete.full_name}} from {{school.name}}?</h3>
      <div class="text-center">
        <button @click="deleteTeacher()" class="btn btn-lg btn-yes btn-primary">Yes</button>
        <button @click="handleCloseModal('deleteTeacher')" class="btn btn-no btn-lg btn-primary">No</button>
      </div>
    </modal>
    <modal title="Delete teacher" v-if="modal.isVisible('deleteInvite')" @close="handleCloseModal('deleteInvite')">
      <h3 class="text-center">Delete {{inviteToDelete.name}}'s invite to {{school.name}}?</h3>
      <div class="text-center">
        <button class="btn btn-lg btn-yes btn-primary" @click="deleteInvite(inviteToDelete)">Yes</button>
        <button @click="handleCloseModal('deleteInvite')" class="btn btn-no btn-lg btn-primary">No</button>
      </div>
    </modal>
    <modal title="Permissions Table" v-if="modal.isVisible('permissions')" @close="handleCloseModal('permissions')">
    <div class="row center-block">
          <div class="box-body">
            <div class="dataTables_wrapper form-inline dt-bootstrap" id="permissions_wrapper">
              <div class="row">
                <div class="col-sm-6">
                  <div id="permissions_length" class="dataTables_length">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <vue-good-table
                    theme="auris-admin"
                    styleClass="vgt-table striped dataTable"
                    :columns="columns.permissions"
                    :rows="permissions"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'admin.hasPermission'">
                        <img v-if="props.row.admin.hasPermission" src="@/assets/img/icons/tick.svg" :alt="getAltText(props.row.name, 'admin')" class="permissions-image">
                      </span>
                      <span v-else-if="props.column.field == 'teacher.hasPermission'">
                        <img v-if="props.row.teacher.hasPermission" src="@/assets/img/icons/tick.svg" :alt="getAltText(props.row.name, 'admin')" class="permissions-image">
                        <p v-if="props.row.teacher.ownClassesOnly"><em>Own classes only</em></p>
                      </span>
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
        </div>
    </div>
    </modal>
    <!-- Modals End -->
    <div class="row btn-actions">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="pull-right box-tools">
          <button v-if="userIsAdmin" class="btn btn-secondary" @click="handleOpenPermissionsModal()">View Permissions</button>
          <button v-if="userIsAdmin" class="btn btn-primary" @click="$router.push({path: `/add-teacher`})">Add Teacher</button>
        </div>
      </div>
    </div>
    <div class="row center-block">
        <div class="box box-info">
          <div class="box-body">
            <div class="dataTables_wrapper form-inline dt-bootstrap" id="teachers1_wrapper">
              <h2>Teachers</h2>

              <div class="row">
                <div class="col-sm-6">
                  <div id="teachers1_length" class="dataTables_length">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 table-responsive">
                  <vue-good-table
                    theme="auris-admin"
                    styleClass="vgt-table striped dataTable"
                    :columns="columns.teachers"
                    :rows="teachers"
                    :search-options="{
                      enabled: true,
                      trigger: 'keyup',
                      skipDiacritics: true,
                      placeholder: 'Search',
                    }"
                    @on-per-page-change="handlePerPageChange($event)"
                    :pagination-options="{
                      enabled: true,
                      mode: 'pages',
                      perPage: parseInt(rowsPerPage),
                      position: 'bottom',
                      perPageDropdown: rowsPerPageOptions,
                      dropdownAllowAll: false,
                      setCurrentPage: 1,
                      nextLabel: 'next',
                      prevLabel: 'prev',
                      rowsPerPageLabel: 'Rows per page',
                      ofLabel: 'of',
                      pageLabel: 'page', // for 'pages' mode
                      allLabel: 'All',
                    }"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                        <button class="btn-link btn-icon-arrow-up" @click.prevent="setAsAdmin(props.row.id, true)" v-if="!props.row.user_manages && props.row.id !== $store.state.user.id" v-if-authorized="{action: 'edit_teacher'}" :disabled="changingTeacherId === props.row.id">
                          <i :class="changingTeacherId === props.row.id ? 'fa fa-spinner fa-pulse' : 'icon icon-arrow-circle-up'"></i> Promote to admin
                        </button>
                        <button class="btn-link btn-icon-arrow-down" @click.prevent="setAsAdmin(props.row.id, false)" v-if="props.row.user_manages && props.row.id !== $store.state.user.id" v-if-authorized="{action: 'edit_teacher'}" :disabled="changingTeacherId === props.row.id">
                          <i :class="changingTeacherId === props.row.id ? 'fa fa-spinner fa-pulse' : 'icon icon-arrow-circle-down'"></i> Demote from admin
                        </button>
                        <button class="btn btn-link btn-icon-trash js-delete" @click.prevent="startDeleteTeacher(props.row.id, props.row.first_name, props.row.last_name)" v-if="props.row.id != $store.state.user.id" v-if-authorized="{action: 'delete_teacher'}">
                          <i class="icon icon-trash"></i> Delete
                        </button>
                      </span>
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
        </div>
        <loading-state :is-loading="isLoading"></loading-state>
      </div>
    </div>
    <div v-if="userIsAdmin" class="row center-block">
        <div class="box box-info">
          <div class="box-body">
            <div class="dataTables_wrapper form-inline dt-bootstrap" id="invites1_wrapper">
              <h2>Invites</h2>
              <div class="row">
                <div class="col-sm-6">
                  <div id="invites1_length" class="dataTables_length">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 table-responsive">
                  <vue-good-table
                    styleClass="vgt-table striped dataTable"
                    theme="auris-admin"
                    :columns="columns.invites"
                    :rows="invites"
                    :search-options="{
                      enabled: true,
                      trigger: 'keyup',
                      skipDiacritics: true,
                      placeholder: 'Search',
                    }"
                    @on-per-page-change="handlePerPageChange($event)"
                    :pagination-options="{
                      enabled: true,
                      mode: 'pages',
                      perPage: parseInt(rowsPerPage),
                      position: 'bottom',
                      perPageDropdown: rowsPerPageOptions,
                      dropdownAllowAll: false,
                      setCurrentPage: 1,
                      nextLabel: 'next',
                      prevLabel: 'prev',
                      rowsPerPageLabel: 'Rows per page',
                      ofLabel: 'of',
                      pageLabel: 'page', // for 'pages' mode
                      allLabel: 'All',
                    }"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'email'">
                        <h3>{{props.row.email}}</h3>
                        <p>{{props.row.name}}</p>
                      </span>
                      <span v-else-if="props.column.field =='status'" class="v-align-top" :class="{'text-danger': (props.row.status === 'pending'), 'text-success': (props.row.status === 'accepted')}">
                          <h3>{{capitaliseFirstLetter(props.row.status)}}</h3>
                      </span>
                      <span v-else-if="props.column.field == 'created_at'">
                          {{formatInviteCreated(props.row.created_at)}}
                      </span>
                      <span v-else-if="props.column.field == 'expires_at'">
                          {{formatInviteExpiry(props.row.expires_at)}}
                      </span>
                      <span v-else-if="props.column.field == 'actions'">
                        <button :disabled="(getButtonState(props.row) !== 'normal')" class="btn-link btn-fixed-width" :class="getButtonState(props.row)" @click="resendInvite(props.row.name, props.row.email, props.row.id)">
                          <i class="icon" :class="getIconClass(getButtonState(props.row))"></i> {{getInviteButtonText(getButtonState(props.row), props.row)}}
                        </button>
                        <button v-if="props.row.status === 'pending'" class="btn btn-link btn-icon-trash" @click="startDeleteInvite(props.row)">
                          <i class="icon icon-trash"></i> Delete invite
                        </button>
                      </span>
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
        </div>
        <loading-state :is-loading="isLoading"></loading-state>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import CurrentUserMixins from '@/components/mixins/CurrentUserMixins';
import TeachersMixin from '@/components/mixins/TeachersMixin';
import LoadingState from '@/components/utils/LoadingState';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/components/classes/Modal';
import InviteMixin from '@/components/mixins/InviteMixin';
import moment from 'moment';
import StringUtils from '@/components/mixins/StringUtils';
import { VueGoodTable } from 'vue-good-table';
import TableMixin from '@/components/mixins/TableMixin';

export default {
  name: 'Teachers',
  components: {
    LoadingState, modal: ModalJS, VueGoodTable,
  },
  data() {
    return {
      teachers: [],
      invites: [],
      response: 'Data Loading...',
      columns: {
        permissions: [
          {
            label: 'Permission',
            field: 'name',
            type: 'string',
            sortable: false,
            tdClass: 'u-vertical-align-top',
          },
          {
            label: 'Admin',
            field: 'admin.hasPermission',
            type: 'boolean',
            sortable: false,
            tdClass: 'vgt-center-align u-vertical-align-top',
            thClass: 'vgt-center-align',
          },
          {
            label: 'Teacher',
            field: 'teacher.hasPermission',
            type: 'boolean',
            sortable: false,
            tdClass: 'vgt-center-align u-vertical-align-top',
            thClass: 'vgt-center-align',
          },
        ],
        teachers: [
          {
            label: 'Teacher',
            field: 'full_name',
            sortable: true,
            firstSortType: 'desc',
          },
          {
            label: 'Role',
            field: 'enterprise_user_role',
            sortable: false,
            formatFn: this.getUserRoleText,
          },
          {
            label: 'Actions',
            field: 'actions',
            sortable: false,
            tdClass: 'actions',
          },
        ],
        invites: [
          {
            label: 'Invitee',
            field: 'email',
            sortable: true,
            firstSortType: 'desc',
          },
          {
            label: 'Invited By',
            field: 'inviter.full_name',
          },
          {
            label: 'Status',
            field: 'status',
            sortable: true,
          },
          {
            label: 'Sent',
            field: 'created_at',
            sortable: false,
          },
          {
            label: 'Expires On',
            field: 'expires_at',
            sortable: false,
          },
          {
            label: 'Actions',
            field: 'actions',
            tdClass: 'actions',
            sortable: false,
          },
        ],
      },
      school: null,
      isLoading: false,
      changingTeacherId: null,
      teacherToDelete: {
        id: null,
        full_name: null,
      },
      inviteToDelete: null,
      modal: new Modal({
        deleteTeacher: false,
        deleteInvite: false,
        permissions: false,
      }),
      sendingInvites: [],
      sentInvites: [],
      permissions: [
        {
          name: 'Add Teacher',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: false,
          },
        },
        {
          name: 'Delete Teacher',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: false,
          },
        },
        {
          name: 'Add Pupils to School',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Delete Pupils from School',
          admin: {
            hasPermission: true,
          },
          teacher: { hasPermission: false },
        },
        {
          name: 'Promote to Admin',
          admin: {
            hasPermission: true,
          },
          teacher: { hasPermission: false },
        },
        {
          name: 'Demote from Admin',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: false,
          },
        },
        {
          name: 'Create Class',
          admin: {
            hasPermission: true,
          },
          teacher: { hasPermission: true },
        },
        {
          name: 'Delete Class',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Add Pupils to Class',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Remove Pupils from Class',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Move Pupils',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Assign Books to Class',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Remove Books from Class',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Assign Books to Pupils',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Remove Books from Pupils',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Reset Passwords',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Assign Teacher to Class',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Remove Teacher from Class',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Edit Account Details',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
        {
          name: 'Reset Password',
          admin: {
            hasPermission: true,
          },
          teacher: {
            hasPermission: true,
          },
        },
      ],
    };
  },
  mixins: [CurrentUserMixins, TeachersMixin, InviteMixin, StringUtils, TableMixin],
  mounted() {
    this.school = this.getUserSchool();
    this.$nextTick(() => {
      this.getTeachersData(this.school.id);
      this.retrieveInvites();
    });
  },
  methods: {
    getAltText(name, role) {
      return `${role} has permission for ${name.toLowerCase()}`;
    },
    getUserRoleText(role) {
      return role === 'admin' ? 'Admin' : 'Teacher';
    },
    handleOpenPermissionsModal() {
      const bodyNodeList = document.querySelectorAll('body');
      bodyNodeList.forEach((n) => {
        n.classList.add('modal-open');
      });
      this.modal.show('permissions');
    },
    retrieveInvites() {
      if (this.userIsAdmin) {
        this.getInvites(this.school.id)
          .then((response) => {
            this.invites = response.data.invites.map((i) => ({ ...i, inviter: { ...i.inviter, full_name: `${i.inviter.first_name} ${i.inviter.last_name}` } }));
          });
      }
    },
    formatInviteCreated(dateString) {
      const mDate = moment(dateString);
      return mDate.format('Do MMMM yyyy');
    },
    formatInviteExpiry(dateString) {
      const mDate = moment(dateString);
      const today = moment().startOf('day');
      return mDate.isBefore(today) ? '' : mDate.format('Do MMMM yyyy');
    },
    handleCloseModal(modalName) {
      const bodyNodeList = document.querySelectorAll('body');
      bodyNodeList.forEach((n) => {
        n.classList.remove('modal-open');
      });
      this.modal.hide(modalName);
      this.teacherToDelete.id = null;
      this.teacherToDelete.full_name = null;
      this.inviteToDelete = null;
    },
    startDeleteTeacher(id, firstName, lastName) {
      this.teacherToDelete.id = id;
      this.teacherToDelete.full_name = `${firstName} ${lastName}`;
      this.modal.show('deleteTeacher');
    },
    startDeleteInvite(invite) {
      this.inviteToDelete = invite;
      this.modal.show('deleteInvite');
    },
    deleteInvite(invite) {
      this.handleCloseModal('deleteInvite');
      this.$http.delete(`/enterprises/${this.school.id}/invites/${invite.id}`)
        .then((r) => {
          this.$apiResponse.renderSuccessMessage(r);
        })
        .catch((e) => {
          this.$apiResponse.renderErrorMessage(e);
        })
        .finally(() => {
          this.retrieveInvites();
        });
    },
    deleteTeacher() {
      this.$http.delete(`/enterprises/${this.school.id}/users/${this.teacherToDelete.id}`)
        .then(() => {
          this.modal.hide('deleteTeacher');
          this.getTeachersData(this.school.id);
          this.teacherToDelete.id = null;
          this.teacherToDelete.full_name = null;
        })
        .catch((error) => {
          this.modal.hide('deleteTeacher');
          this.changingTeacherId = false;
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    getTeachersData() {
      this.isLoading = true;
      this.getTeachers(this.school.id)
        .then((teachers) => {
          this.teachers = teachers.map((t) => ({ ...t, full_name: `${t.first_name} ${t.last_name}` }));
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    toggleLoading() {
      this.loading = (this.loading === '') ? 'loading' : '';
    },
    resendInvite(name, email, id) {
      this.sendingInvites.push({ name, email, id });
      this.$http.post(`enterprises/${this.school.id}/invites`, { email, name })
        .then(() => {
          this.sendingInvites = [];
          this.sentInvites.push({ name, email, id });
        })
        .catch(() => {
          this.sendingInvites = [];
        });
    },
    setAsAdmin(teacherId, admin) {
      this.changingTeacherId = teacherId;
      this.$http.post(`enterprises/${this.school.id}/users/${teacherId}/setadmin`, { admin })
        .then((response) => {
          this.changingTeacherId = null;
          this.$apiResponse.renderSuccessMessage(response, admin ? 'The teacher has ben promoted' : 'The teacher has been demoted');
          this.switchTeacherStatus(teacherId, admin);
        })
        .catch((error) => {
          this.changingTeacherId = false;
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    switchTeacherStatus(teacherId, manageUsers) {
      this._.forEach(this.teachers, (teacher) => {
        if (teacher.id === teacherId) {
          teacher.user_manages = manageUsers;
        }
      });
    },
    getButtonState(invite) {
      const isExpired = invite.status === 'expired';
      const sentInvites = this.sentInvites.filter((i) => i.id === invite.id);
      const sendingInvites = this.sendingInvites.filter((i) => i.id === invite.id);
      const isSentInvite = sentInvites.length > 0;
      const isSendingInvite = sendingInvites.length > 0;
      if (invite.status === 'accepted') {
        return 'accepted';
      } if (isExpired) {
        return 'expired';
      } if (isSentInvite) {
        return 'sent';
      } if (isSendingInvite) {
        return 'loading';
      }
      return 'normal';
    },
    getIconClass(buttonState) {
      if (buttonState === 'accepted') {
        return 'icon-ban__grey';
      } if (buttonState === 'sent') {
        return 'icon-email__sent';
      } if (buttonState === 'loading') {
        return 'fa fa-spinner fa-spin';
      }
      return 'icon-envelope__blue';
    },
    getInviteButtonText(buttonState, invite) {
      if (invite.status === 'accepted') {
        return 'None';
      } if (buttonState === 'expired') {
        return 'Resend invite';
      } if (buttonState === 'sent') {
        return 'Resent';
      } if (buttonState === 'loading') {
        return 'Resending...';
      }
      return 'Resend invite';
    },
  },
  computed: {
    userIsAdmin() {
      return this.$store.state.user.enterprise_user_role === 'admin';
    },

  },
};
</script>

<style>
</style>
