<template>
  <!-- Main content -->
  <section class="content add-pupils">
    <modal title="Pupil creation has started" :showFooter="true" v-if="modal.isVisible('email')" @close="handleCloseModal()" >
        <p class="text-muted">We are generating your pupil accounts now and will send you the login details to your email inbox in just a few minutes.</p>
    </modal>
    <div class="row">
      <div class="col-md-6">
        <div class="box box-info">
          <div class="box-header with-border">
            <h3 class="box-title">Select a class</h3>
          </div>
          <div class="box-body">
            <p class="text-muted">Select the class you wish to add the pupil(s) to...</p>
            <ValidationObserver v-slot="{ passes }" ref="form">
              <form ref="observer" @submit.prevent="passes()">
              <div class="form-group">
                <div class="input-group">
                  <label for="group">Choose a class</label>
                  <select class="form-control" id="group" name="group" @change="handleChangeGroup($event)">
                    <option :selected="groupSelected || groupId ? '' : 'selected'" value="" disabled="true">Please select an option</option>
                    <option :value="null">Assign to class later</option>
                    <option :value="group.id" v-for="group in groups" :key="group.id" :selected="parseInt(groupId) === group.id">{{ group.name }}</option>
                  </select>
                </div>
              </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>

    </div>
    <div class="row" v-if="groupSelected || groupId">
      <div class="col-md-6">
        <div class="box box-info">
          <div class="box-header with-border">
            <h3 class="box-title">Manually add a pupil...</h3>
          </div>
          <div class="box-body">
            <p class="text-muted">Enter the pupils names you wish to add to the {{ addingPupilsToClass === true ?
              'class' : 'school' }}.</p>
            <ValidationObserver v-slot="{ passes }" ref="form">
              <form ref="observer" @submit.prevent="passes(addReaders)">
                <table class="table emails">
                  <thead>
                  <tr>
                    <th>Name</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(pupil, index) in pupils" :key="index">
                    <td>
                      <ValidationProvider :rules="{required: index === 0}" name="Name"
                                          v-slot="{ errors }">
                        <div class="form-group" :class="{ 'has-error': errors[0] }">
                          <div class="input-group input-group-sm">
                            <div class="input-group-addon"><i class="icon icon-user"></i>
                            </div>
                            <input type="text"
                                   v-model="pupil.name"
                                   :key="index"
                                   :name="'name'+(index+1)"
                                   :id="'name'+(index+1)"
                                   @keydown="addPupil(index)"
                                   class="form-control">
                          </div>
                        </div>
                        <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <loading-button :is-loading="isLoading" class="btn btn-primary">Add Pupils</loading-button>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="box box-info">
          <div class="box-header with-border">
            <h3 class="box-title">...Or upload a file</h3>
          </div>
          <div>
          </div>
          <div class="box-body">
            <p class="text-muted">Fill in our import template and upload it here to setup many pupil accounts more
              easily. This is great if you already have your pupils listed in a spreadsheet somewhere.</p>
            <drag-drop-file @submit="sendFile($event)" :is-loading="isLoading">
              <slot>
                <a class="btn btn-default pull-right"
                   href="https://s3-eu-west-1.amazonaws.com/auris-api-exports/pupils_example.xlsx"
                   v-if="!isLoading">Download Import Template</a>
                <button disabled="disabled" class="btn btn-default pull-right" v-else>Download Import Template</button>
              </slot>
            </drag-drop-file>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import axios from 'axios';
import LoadingButton from '@/components/utils/LoadingButton';
import PupilsMixin from '@/components/mixins/PupilsMixin';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/components/classes/Modal';
import DragDropFile from '@/components/utils/DragDropFile';
import ClassesMixin from '@/components/mixins/ClassesMixin';

export default {
  name: 'AddPupil',
  mixins: [PupilsMixin, ClassesMixin],
  data() {
    return {
      pupils: [{
        name: '',
      }],
      modal: new Modal({
        email: false,
      }),
      file: null,
      isLoading: false,
      addingPupilsToClass: null,
      schoolId: null,
      groupId: null,
      termsAndConditionsMissing: false,
      groups: null,
      groupSelected: false,
      selectedGroup: null,
    };
  },
  components: {
    DragDropFile,
    LoadingButton,
    modal: ModalJS,
    'loading-button': LoadingButton,
  },
  mounted() {
    this.schoolId = this.$store.state.user.enterprise.id;
    this.groupId = this.$route.params.classId;
    if (this.groupId !== null) {
      this.selectedGroup = this.groupId;
    }
    this.getGroups(this.schoolId).then((groups) => {
      this.groups = groups;
    }).catch((error) => {
      this.$apiResponse.renderErrorMessage(error);
    });
  },
  methods: {
    handleChangeGroup(e) {
      this.selectedGroup = e.target.value;
      this.groupSelected = true;
    },
    handleCloseModal() {
      this.pupils = [{ name: '' }];

      this.modal.hide('email');
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    addPupil(index) {
      if (index + 1 === this.pupils.length) {
        this.pupils.push({
          name: '',
        });
      }
    },
    removePupil(index) {
      this.pupils.splice(index, 1);
    },
    addReaders() {
      this.isLoading = true;
      this.createPupils(this.pupils, this.schoolId, this.selectedGroup)
        .then(() => {
          this.isLoading = false;
          this.modal.show('email');
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    sendFile(e) {
      axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      const data = new FormData();
      data.append('readers_file', e[0]);
      this.isLoading = true;
      let url = `enterprises/${this.schoolId}/readers`;
      if (this.selectedGroup && this.selectedGroup !== '') {
        url = `enterprises/${this.schoolId}/groups/${this.selectedGroup}/readers`;
      }
      this.$http.post(url, data)
        .then((response) => {
          if (response.data.links) {
            this.links = response.data.links;
          }

          this.isLoading = false;
          this.$apiResponse.renderSuccessMessage(response, 'The pupils have been created and added to your school');
          this.modal.show('email');
        })
        .catch((error) => {
          this.isLoading = false;
          this.$apiResponse.renderErrorMessage(error);
        });
    },
  },
  computed: {
    userIsAdmin() {
      return this.$store.state.user.enterprise_user_role === 'admin';
    },
  },
};
</script>
