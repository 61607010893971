<template>
  <div>
    <ul class="sidebar-extra">
      <li class="school">
        <span class="page">{{ getCurrentSchoolName }}</span>
      </li>
    </ul>

    <ul class="sidebar-menu">
      <li class="header">ACCOUNT</li>

      <router-link tag="li" active-class="active" class="pageLink" to="/" exact>
        <a><i class="icon-mask icon-dashboard"></i> <span class="page">Dashboard</span></a>
      </router-link>

      <router-link tag="li" active-class="active" class="pageLink pageLink__last" to="/account" exact>
        <a><i class="icon-mask icon-account"></i> <span class="page">Your Account</span></a>
      </router-link>

      <li class="header">SCHOOL</li>

      <router-link tag="li" active-class="active" class="pageLink" to="/school" exact>
        <a><i class="icon-mask icon-school"></i> <span class="page">Manage School</span></a>
      </router-link>

      <router-link tag="li" active-class="active" class="pageLink" to="/subscription" exact v-if-authorized="{action: 'manage_subscription'}">
        <a><i class="icon-mask icon-subscription"></i> <span class="page">Subscription</span></a>
      </router-link>

      <router-link tag="li" active-class="active" class="pageLink" to="/teachers" exact>
        <a><i class="icon-mask icon-teachers"></i> <span class="page">Teachers</span></a>
      </router-link>

      <router-link tag="li" active-class="active" class="pageLink" to="/classes" exact>
        <a><i class="icon-mask icon-classes"></i> <span class="page">Classes</span></a>
      </router-link>

      <router-link tag="li" active-class="active" class="pageLink pageLink__last" to="/pupils" exact>
        <a><i class="icon-mask icon-pupils"></i> <span class="page">Pupils</span></a>
      </router-link>

      <li class="header">HELP &amp; SUPPORT</li>
      <router-link tag="li" active-class="active" class="pageLink" to="/overview" exact>
        <a><i class="icon-mask icon-overview"></i> <span class="page">Overview</span></a>
      </router-link>
      <router-link tag="li" active-class="active" class="pageLink" to="/userguide" exact>
        <a><i class="icon-mask icon-user-guide"></i> <span class="page">User guide</span></a>
      </router-link>
      <router-link tag="li" active-class="active" class="pageLink" to="/videoguides" exact>
        <a><i class="icon-mask icon-video"></i> <span class="page">Video Guides</span></a>
      </router-link>
      <router-link tag="li" active-class="active" class="pageLink" to="/templates" exact>
        <a><i class="icon-mask icon-templates"></i> <span class="page">Templates</span></a>
      </router-link>
      <router-link tag="li" active-class="active" class="pageLink" to="/faq" exact>
        <a><i class="icon-mask icon-faq"></i> <span class="page">FAQs</span></a>
      </router-link>
      <router-link tag="li" active-class="active" class="pageLink pageLink__last" to="/contact" exact>
        <a><i class="icon-mask icon-contact"></i> <span class="page">Contact</span></a>
      </router-link>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CurrentUserMixins from '@/components/mixins/CurrentUserMixins';

export default {
  name: 'SidebarName',
  mixins: [CurrentUserMixins],
  methods: {
    toggleMenu(event) {
      // remove active from li
      const active = document.querySelector('li.pageLink.active');

      if (active) {
        active.classList.remove('active');
      }
      // window.$('li.pageLink.active').removeClass('active')
      // Add it to the item that was clicked
      event.toElement.parentElement.className = 'pageLink active';
    },
  },
  computed: {
    ...mapGetters([
      'getCurrentSchoolName',
    ]),
  },
};
</script>
<style>
  /* override default */
  .sidebar-menu>li>a {
    padding: 12px 15px 12px 15px;
  }

  .sidebar-menu li.active>a>.fa-angle-left, .sidebar-menu li.active>a>.pull-right-container>.fa-angle-left {
    animation-name: rotate;
    animation-duration: .2s;
    animation-fill-mode: forwards;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-90deg);
    }
  }
</style>
