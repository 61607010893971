<template>
  <button data-testid="loadingButton" class="btn btn-primary" :disabled="loading || disabled" @click="$emit('click')">
    <span v-if="loading"><i data-testid="spinner" class="fa fa-spinner fa-spin"></i></span>
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: 'LoadingButton',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    loading() {
      return this.isLoading;
    },
    disabled() {
      return this.isDisabled;
    },
  },
};
</script>

<style scoped>
  .fa {
    margin-right: 10px;
  }
</style>
