export default {
  methods: {
    validateForm(data) {
      this.$emit('validated', data);
    },
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value });
    },
  },
};
