export default {
  methods: {
    isLoggedIn() {
      return this.$router.app.$store.state.token && this.$router.app.$store.state.token !== 'null';
    },
    hasReaders() {
      let hasReaders = false;
      this._.each(this.$store.state.user.readers, (reader) => {
        if (reader.type !== 'demo') {
          hasReaders = true;
        }
      });
      return hasReaders;
    },
    getUserSchool() {
      return this.$store.state.user.enterprise ? this.$store.state.user.enterprise : null;
    },
    getMe() {
      return new Promise((resolve, reject) => {
        this.$http.get('users/me')
          .then((response) => {
            const { data } = response;
            if (data.user) {
              this.$store.dispatch('setUser', data.user);
            }
            resolve(data.user);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
  },
};
