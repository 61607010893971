<template>
  <div class="user-guide-container">
    <tabs @tabSelected="selectTab($event)">
      <tab v-for="tab in tabs" :key="tab.id" :title="tab.title" :iconClass="tab.iconClass">
        <div class="user-guide-tab-container">
          <ul class="links-list">
            <li v-for="section in tab.sections" :key="section.id" :class="{active: section.id === currentSection.id}" @click="showContent(section)"><span>{{section.title}}</span></li>
          </ul>
          <div class="content-viewer-container">
            <guide-content-viewer
              :shouldShowPreviousButton="previousSectionExists"
              :shouldShowNextButton="nextSectionExists"
              :shouldShowVideoButton="currentSection.hasOwnProperty('video')"
              @previousButtonClicked="changeSection(tabs[currentTabIndex].sections, subtractOne)"
              @nextButtonClicked="changeSection(tabs[currentTabIndex].sections, addOne)"
              @videoButtonClicked="showVideo"
              :shouldShowContent="contentIsVisible"
            >
              <img :src="currentSection.content" alt="">
            </guide-content-viewer>
        </div>
        </div>

      </tab>
    </tabs>

    <!-- Modal-->
    <modal :title="currentSection.title" v-if="modal.isVisible('video')" @close="modal.hide('video')" :showFooter="false">
      <vimeo-player :video-id="currentSection.video.id" :h="currentSection.video.h" />
    </modal>
  </div>
</template>

<script>
import Tab from '@/components/partials/Tab';
import Tabs from '@/components/partials/Tabs';
import GuideContentViewer from '@/components/partials/GuideContentViewer';
import UserGuideMixin from '@/components/mixins/UserGuideMixin';
import addingAClass from '@/assets/img/user-guide-sections/adding-a-class.png';
import addingPupils from '@/assets/img/user-guide-sections/adding-pupils.png';
import addingTeachers from '@/assets/img/user-guide-sections/adding-teachers.png';
import assigningBooksToIndividuals from '@/assets/img/user-guide-sections/assigning-books-to-individual.png';
import assigningBooksToWholeClass from '@/assets/img/user-guide-sections/assigning-books-to-whole-class.png';
import loggingIntoApp from '@/assets/img/user-guide-sections/logging-into-app.png';
import pupilReadingData from '@/assets/img/user-guide-sections/pupil-reading-data.png';
import registerAUser from '@/assets/img/user-guide-sections/register-a-user.png';
import teacherRegistrationProcess from '@/assets/img/user-guide-sections/teacher-registration-process.png';
import unassigningBooksFromClass from '@/assets/img/user-guide-sections/unassigning-books-class.png';
import unassigningBooksFromIndividuals from '@/assets/img/user-guide-sections/unassigning-books-individuals.png';
import wholeClassReadingData from '@/assets/img/user-guide-sections/whole-class-reading-data.png';
import yourSchoolLoginDetails from '@/assets/img/user-guide-sections/your-school-login-details.png';
import parentAccess from '@/assets/img/user-guide-sections/parent-access.png';
import gettingPupilsSignedUp from '@/assets/img/user-guide-sections/getting-pupils-signed-up.png';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/components/classes/Modal';
import VimeoPlayer from '@/components/utils/VimeoPlayer';

export default {
  name: 'UserGuide',
  components: {
    Tab,
    Tabs,
    GuideContentViewer,
    modal: ModalJS,
    VimeoPlayer,
  },
  mixins: [UserGuideMixin],
  props: ['itemId', 'tabId'],
  data() {
    return {
      tabs: [
        {
          id: 'a',
          title: 'Getting your school online with Fonetti',
          iconClass: 'icon-user-guide-dark',
          sections: [
            {
              id: 1,
              title: 'Your School login details',
              content: yourSchoolLoginDetails,
            },
            {
              id: 2,
              title: 'Register a user',
              content: registerAUser,
            },
            {
              id: 3,
              title: 'Adding teachers',
              content: addingTeachers,
              video: {
                id: '745521357',
                h: 'b2f5191da0',
              },
            },
            {
              id: 4,
              title: 'The teacher registration process',
              content: teacherRegistrationProcess,
            },
            {
              id: 5,
              title: 'Adding classes',
              content: addingAClass,
              video: {
                id: '745365511',
                h: 'c3dbd37d8e',
              },
            },
            {
              id: 6,
              title: 'Adding pupils',
              content: addingPupils,
              video: {
                id: '745505950',
                h: '59ecc20677',
              },
            },
          ],
        },
        {
          id: 'b',
          title: 'Getting the most out of Fonetti',
          iconClass: 'icon-user-guide-dark',
          sections: [
            {
              id: 7,
              title: 'Assigning books to a whole class',
              content: assigningBooksToWholeClass,
            },
            {
              id: 8,
              title: 'Assigning books to individual pupils',
              content: assigningBooksToIndividuals,
              video: {
                id: '745723837',
                h: '3f0dd2837f',
              },
            },
            {
              id: 9,
              title: 'Unassigning a book from a whole class',
              content: unassigningBooksFromClass,
            },
            {
              id: 10,
              title: 'Unassigning a book from an individual pupil',
              content: unassigningBooksFromIndividuals,
            },
            {
              id: 11,
              title: 'Whole class reading data',
              content: wholeClassReadingData,
              video: {
                id: '745761352',
                h: 'a79d88df70',
              },
            },
            {
              id: 12,
              title: 'Pupil reading data',
              content: pupilReadingData,
              video: {
                id: '755098122',
                h: '666379c914',
              },
            },
            {
              id: 13,
              title: 'Logging into the Fonetti app',
              content: loggingIntoApp,
            },
            {
              id: 14,
              title: 'How parents can access a child’s Fonetti ‘school’ account at home',
              content: parentAccess,
            },
            {
              id: 15,
              title: 'Getting pupils signed up to Fonetti ‘home’ subscription',
              content: gettingPupilsSignedUp,
            },
          ],
        },
      ],
      currentTabIndex: 0,
      modal: new Modal({
        video: false,
      }),
    };
  },
  methods: {
    name() {
      return 'User Guide';
    },
    selectTab(index) {
      this.currentSection = {};
      this.contentIsVisible = false;
      this.currentTabIndex = index;
    },
    showVideo() {
      if (this.currentSection && Object.hasOwnProperty.call(this.currentSection, 'video')) {
        this.modal.show('video');
      }
    },
  },
  computed: {
    previousSectionExists() {
      return !!this.tabs[this.currentTabIndex].sections[this.currSectionIndex - 1];
    },
    nextSectionExists() {
      return !!this.tabs[this.currentTabIndex].sections[this.currSectionIndex + 1];
    },
    currSectionIndex() {
      return this.tabs[this.currentTabIndex].sections.indexOf(this.currentSection);
    },
  },
  mounted() {
    if (this.$route.params.tabId && this.$route.params.itemId) {
      const [tab] = this.tabs.filter((t) => t.id === this.$route.params.tabId);
      const [section] = tab.sections.filter((s) => s.id === parseInt(this.$route.params.itemId, 10));
      this.showContent(section);
    }
  },
};
</script>

<style lang="scss" scoped>
  .user-guide-container {
    height: 100%;
    width: calc(100% - 60px);
    display: block;

    .vimeo-player {
      width: 100%;
      height: 400px;
    }
  }

  .user-guide-tab-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    .links-list {
      margin: 30px 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .content-viewer-container {
    width: calc(100% - 444px);
    height: 100%;
    margin-right: 30px;
    .answer-locator {
      border-radius: 10px;
      margin: 30px 0;

      img {
        max-width: 100%;
      }
    }

  }
</style>
