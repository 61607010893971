<template>
  <!-- Main content -->
  <section class="content">
    <div class="row" v-if="ready">
      <div class="col-md-12 class-info">
        <div class="pull-right box-tools">
          <button class="btn btn-primary" v-if-authorized="{action: 'edit_school'}" @click="$router.push({path: '/edit-school'})">Update the school details</button>
          <button class="btn btn-primary" @click="$router.push({path: `/school/add-readers`})">Add Pupils</button>
        </div>
      </div>
    </div>
    <div class="row" v-if="ready && !school.can_enable_recordings">
      <div class="col-md-12">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">School Information</h3>
          </div>
          <div class="box-body no-padding table-responsive">
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <th class="cell-25">Name</th>
                  <td>{{ school.name }}</td>
                </tr>
                <tr>
                  <th>URN</th>
                  <td>{{ school.urn }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row match-height" v-else-if="ready && school.can_enable_recordings">
      <div class="col-md-6">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">School Information</h3>
          </div>
          <div class="box-body no-padding table-responsive">
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <th class="cell-25">Name</th>
                  <td>{{ school.name }}</td>
                </tr>
                <tr>
                  <th>URN</th>
                  <td>{{ school.urn }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">Recordings</h3>
          </div>
          <div class="box-body">
            <p>
              Recordings are taken by the Auris team to help improve our speech recognition systems for your pupils'
              accents. Please ensure you have any required pupil or parent content prior to enabling recordings.
            </p>
            <div class="flex flex-justify-content-end">
              <loading-button class="btn btn-primary" :is-loading="updatingRecordingEnabled" @click="handleChangeRecordingEnabled()">{{school.recording_enabled ? 'Disable Recordings' : 'Enable Recordings'}}</loading-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="ready">
      <div class="col-md-6">
        <contact-details v-model="school.primaryContact" title="Primary Contact"></contact-details>
      </div>
      <div class="col-md-6">
        <contact-details v-model="school.billingContact" title="Billing Contact"></contact-details>
      </div>
    </div>

  </section>
  <!-- /.content -->
</template>

<script>
import SchoolMixins from '@/components/mixins/SchoolMixins';
import ContactDetails from '@/components/partials/ContactDetails';
import CurrentUserMixins from '@/components/mixins/CurrentUserMixins';
import LoadingButton from '@/components/utils/LoadingButton';

export default {
  name: 'School',
  data() {
    return {
      loading: false,
      errorMsg: null,
      schoolId: null,
      ready: false,
      school: {},
      updatingRecordingEnabled: false,
    };
  },
  mixins: [SchoolMixins, CurrentUserMixins],
  mounted() {
    this.school = this.getUserSchool();
    this.$nextTick(() => {
      this.getSchool(this.school.id)
        .then((school) => {
          this.school = this.formatSchool(school);
          this.schoolId = this.school.id;
          this.ready = true;
          this.loading = false;
        });
    });
  },
  components: {
    ContactDetails,
    'contact-details': ContactDetails,
    LoadingButton,
  },
  methods: {
    handleChangeRecordingEnabled() {
      const newState = !this.school.recording_enabled;
      this.updatingRecordingEnabled = true;
      this.$http.post(`/enterprises/${this.school.id}`, { recording_enabled: newState ? 1 : 0 })
        .then(() => {
          this.school.recording_enabled = newState;
          this.updatingRecordingEnabled = false;
        });
    },
  },
};
</script>
