export default {
  data() {
    return {
      EntityType: {
        CLASS: 0,
        PUPIL: 1,
      },
      readingLevelStart: 1,
      readingLevelEnd: 6,
      maxReadingLevel: 6,
      schoolId: null,
    };
  },
  methods: {
    getBooks(options) {
      const {
        entityType, schoolId, groupId, pupilId,
      } = options;
      let url;
      switch (entityType) {
        case this.EntityType.CLASS:
          url = `enterprises/${schoolId}/groups/${groupId}/books`;
          break;
        case this.EntityType.PUPIL:
          url = `enterprises/${schoolId}/readers/${pupilId}/books`;
          break;
        default:
          url = '';
      }

      return new Promise((resolve, reject) => {
        this.$http.get(url)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
    fixCoverImageUrl(url, size = 400, side = 'height') {
      switch (side) {
        case 'height':
          return url.replace('0x200', `0x${size}`);
        case 'width':
          return url.replace('0x200', `${size}x200`);
        default:
          return url;
      }
    },
    assignBook(options) {
      const {
        entityType, id, pupilId, groupId,
      } = options;

      let url;
      switch (entityType) {
        case this.EntityType.CLASS:
          url = `enterprises/${this.$attrs.schoolId}/groups/${groupId}/books/${id}`;
          break;
        case this.EntityType.PUPIL:
          url = `enterprises/${this.$attrs.schoolId}/readers/${pupilId}/books/${id}`;
          break;
        default:
          url = '';
      }

      this.assigningBook = true;
      this.$http.post(url)
        .then(() => {
          this.$emit('book-assignment-changed', id, true, entityType);
          this.assigningBook = false;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
          this.assigningBook = false;
        });
    },
    assignBooks(options) {
      const {
        entityType, bookIds, pupilId, groupId,
      } = options;

      let url;
      switch (entityType) {
        case this.EntityType.CLASS:
          url = `enterprises/${this.$attrs.schoolId}/groups/${groupId}/books`;
          break;
        case this.EntityType.PUPIL:
          url = `enterprises/${this.$attrs.schoolId}/readers/${pupilId}/books`;
          break;
        default:
          return;
      }

      this.assigningBook = true;
      this.$http.post(url, { book_ids: bookIds })
        .then(() => {
          this.$emit('book-assignment-changed', bookIds, true, entityType);
          this.assigningBook = false;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
          this.assigningBook = false;
        });
    },
    removeBook(options) {
      const {
        entityType, id, pupilId, schoolId,
      } = options;
      let url;
      switch (entityType) {
        case this.EntityType.CLASS:
          url = `enterprises/${schoolId || this.$attrs.schoolId}/groups/${this.groupId}/books/${id}`;
          break;
        case this.EntityType.PUPIL:
          url = `enterprises/${schoolId || this.$attrs.schoolId}/readers/${pupilId}/books/${id}`;
          break;
        default:
          url = '';
      }
      this.removingBook = true;
      this.$http.delete(url)
        .then(() => {
          this.$emit('book-assignment-changed', id, false, entityType);
          this.removingBook = false;
          this.modal.hide('unassign');
          this.resetBookToRemove();
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
          this.removingBook = false;
        });
    },
    removeBooks(options) {
      const {
        entityType, bookIds, pupilId, schoolId,
      } = options;
      let url;
      switch (entityType) {
        case this.EntityType.CLASS:
          url = `enterprises/${schoolId || this.$attrs.schoolId}/groups/${this.groupId}/books/remove`;
          break;
        case this.EntityType.PUPIL:
          url = `enterprises/${schoolId || this.$attrs.schoolId}/readers/${pupilId}/books/remove`;
          break;
        default:
          return;
      }
      this.removingBook = true;
      this.$http.post(url, { book_ids: bookIds })
        .then(() => {
          this.$emit('book-assignment-changed', bookIds, false, entityType);
          this.removingBook = false;
          this.modal.hide('unassign');
          this.resetBookToRemove();
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
          this.removingBook = false;
        });
    },
    updateBookAssignment(bookId, assigned, entityType) {
      let books = [];
      let bookIds = [];
      if (!Array.isArray(bookId)) {
        bookIds.push(bookId);
      } else {
        bookIds = bookId;
      }

      books = this.books.filter((b) => (bookIds.includes(b.id)));
      books.forEach((book) => {
        switch (entityType) {
          case this.EntityType.CLASS:
            book.is_assigned_via_group = assigned;
            book.is_assigned = assigned;
            break;
          case this.EntityType.PUPIL:
            book.is_assigned_to_reader = assigned;
            book.is_assigned = book.is_assigned_via_group || book.is_assigned_to_reader;
            break;
          default:
            break;
        }
      });
    },
  },

};
