<template>
  <section class="content">
        <library-info
          :books="books"
          :currentEntityType="EntityType.PUPIL"
          @show-unassign-modal="modal.show('unassign')"
          @update-book-to-remove="updateBookToRemove($event)"
          @book-assignment-changed="updateBookAssignment"
          v-bind:schoolId="schoolId"
          :pupilName="pupilName"
        />
  </section>
</template>

<script>
import BooksMixin from '@/components/mixins/BooksMixin';
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import ClassesMixin from '@/components/mixins/ClassesMixin';
import LibraryInfo from '../components/partials/LibraryInfo';

export default {
  name: 'pupilBooks',
  mixins: [BooksMixin, CurrentUserMixin, ClassesMixin],
  components: {
    LibraryInfo,
  },
  data() {
    return {
      books: [],
      pupilName: '',
    };
  },
  created() {
    this.$store.dispatch('clearCurrentPupil');
  },
  mounted() {
    const school = this.getUserSchool();
    this.schoolId = school.id;
    this.getBooksData();
  },
  methods: {
    getBooksData() {
      this.getBooks({
        entityType: this.EntityType.PUPIL,
        schoolId: this.schoolId,
        groupId: this.groupId,
        pupilId: this.pupilId,
      })
        .then((data) => {
          this.books = data.books;
          if (data.meta && data.meta.reader_name) {
            this.pupilName = data.meta.reader_name;
            this.$store.dispatch('setCurrentPupil', { name: this.pupilName });
          }
        });
    },
  },
  computed: {
    pupilId() {
      return this.$route.params.id;
    },
  },
};
</script>
