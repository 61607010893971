<template>
  <div class="overlay" v-if="loading">
    <i class="fa fa-refresh fa-spin"></i>
  </div>
</template>

<script>
export default {
  name: 'LoadingState',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    loading() {
      return this.isLoading;
    },
  },
};
</script>
