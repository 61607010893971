<template>
  <div class="chart-box-wrapper">
    <div class="chart-box">
      <div :class="'apexchart-' + chart.name"></div>
      <loading-state :isLoading="isLoading"/>
    </div>
  </div>
</template>

<script>
import ChartLoadingState from '@/components/utils/ChartLoadingState';

export default {
  name: 'chart',
  props: {
    chart: {
      required: true,
    },
    isLoading: {
      required: false,
      default: false,
    },
  },
  components: { 'loading-state': ChartLoadingState },
};
</script>

<style>
  @import url('../assets/scss/main.scss');
  .chart-box {
    position:relative;
  }
</style>
