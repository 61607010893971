<template>
    <a :href="href" :download="suggestedDownloadFileName" class="button-link">
      <button class="download" :class="{'light-green' : lightGreen, 'small-font' : smallFont}"><span class="button-span">{{ text }}</span><i class="icon icon-download"></i></button>
    </a>
</template>

<script>
export default {
  name: 'download-button',
  props: {
    text: {
      type: String,
      default: 'Download',
    },
    href: {
      type: String,
      required: true,
    },
    suggestedDownloadFileName: {
      type: String,
      required: true,
      default: '',
    },
    lightGreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    smallFont: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: 20px;
  color: #fff;
  font-size: 0.8em;
  height: 2em;
  font-weight: 400;
  span {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
  .icon {
    margin: 0;
  }
  .icon-download {
    width: 12px;
  }
}

button.small-font {
  text-transform: none;
  height: 20px;
  font-size: 1em;
  margin-top:3px;
  margin: 0;
  padding: 0px 10px;
  font-weight: 300;
  width: 100%;
  .icon {
    height: 1em;
    margin: 0;
    padding: 0;
  }
}

.download {
  background-color: #24abc9;
}

.download.light-green {
  background-color: #22C7C3;
  font-size: 14px;
}

button:hover {
  background-color: #ffce4e;
  color: #22C7C3;
  .icon-download {
    content: url("../../assets/img/icons/download-hover.svg");
  }
}

.download.light-green:hover {
  background-color: #ffce4e;
  color: #22C7C3;
}
</style>
