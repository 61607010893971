<template>
  <div class="flexbox-grid-container u-margin-top-65">
    <grid-item
      title="Contact information"
      iconClass="icon-contact-dark"
      :hasViewAll="false"
      :withArrow="false"
    >
      <ul class="links-list">
        <li><contact-link :isPhoneNumber="true" :text="'0203 004 9658'" /></li>
        <li><contact-link :isEmail="true" :text="'schools@fonetti.com'" /></li>
      </ul>

      <p>Please use the form on the right to get help from our support team. We often reply in just a few hours during our office opening times. Drop us a call if your query is urgent and needs immediate attention, or if you'd just prefer to talk to someone.</p>

    </grid-item>

    <grid-item
      title="Ask for help"
      iconClass="icon-help"
      :hasViewAll="false"
      :withArrow="false"
    >
    <iframe frameborder="0" style="height:600px;width:100%;border:none;" src='https://forms.zohopublic.eu/auristech/form/Askforhelp/formperma/kfpDevTr5JEDOPgCUD1ixpaThYD-gxG_JWyYTCXgRqo'></iframe>
    </grid-item>
  </div>
</template>

<script>
import GridItem from '@/components/partials/GridItem';
import ContactLink from '@/components/partials/ContactLink';

export default {
  name: 'Contact',
  components: { GridItem, ContactLink },
};
</script>

<style lang="scss" scoped>
  p {
    font-size: 1em;
    font-weight: 200;
    color: #9999A0;
    margin: 0 20px 30px 20px;
  }
</style>
