<template>
  <div v-if="isActive" class="tab-body">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'tab',
  props: {
    title: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style lang="scss" scoped>
  .tab-body {
    background-color: #fff;
    margin: 0 30px;
    border: 1px solid #939797;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
  }
</style>
