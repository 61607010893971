<template>
  <div>
    <span class="contact-link-button-locator" v-if="isPhoneNumber">Phone: <a class="contact-link" :href="`tel:${text}`">{{text}}</a></span>
    <span class="contact-link-button-locator" v-if="isEmail">Contact email: <a class="contact-link" :href="`mailto:${text}`">{{text}}</a></span>
  </div>
</template>

<script>
export default {
  name: 'contact-link',
  props: {
    isPhoneNumber: {
      type: Boolean,
      default: false,
    },
    isEmail: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
