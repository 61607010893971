export default {
  methods: {
    getGroups(schoolId) {
      return new Promise((resolve, reject) => {
        this.$http.get(`enterprises/${schoolId}`)
          .then((response) => {
            const groups = response.data.enterprise.groups ? response.data.enterprise.groups : [];
            resolve(groups);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
    getGroup(schoolId, groupId) {
      return new Promise((resolve, reject) => {
        this.$http.get(`enterprises/${schoolId}/groups/${groupId}`)
          .then((response) => {
            const group = response.data.group ? response.data.group : {};
            resolve(group);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
  },
};
