<template>
  <!-- Main content -->
  <section class="content">
    <!-- Modals -->
    <modal title="Delete pupil" v-if="modal.isVisible('delete')" @close="handleCloseModal()">
      <h3 class="text-center">Remove {{pupilToDelete.name}} from {{school.name}}?</h3>
      <p class="text-center text-danger">The pupil account and data will be permanently deleted.</p>
      <hr>
      <div class="text-center button-locator">
        <button @click="deletePupil()" class="btn btn-lg btn-yes btn-primary">Yes</button>
        <button @click="handleCloseModal()" class="btn btn-no btn-lg btn-primary">No</button>
      </div>
    </modal>
    <!--Title-->
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="pull-right box-tools">
          <button class="btn btn-primary" @click="$router.push('/school/add-readers')">Add Pupils</button>
        </div>
      </div>
    </div>
    <!--Pupil DataTable -->
    <div class="row">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-body">
            <multiselect v-model="selectedGroups" :options="groups" @select="addGroupSearchTerms" @remove="removeGroupSearchTerms" :hide-selected="true" :multiple="true" placeholder="Filter by class"></multiselect>
            <div class="dataTables_wrapper form-inline dt-bootstrap" id="pupils_wrapper">
              <div class="row">
                <div class="col-sm-6">
                  <div class="dataTables_length">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 table-responsive">
                  <vue-good-table
                    styleClass="vgt-table striped"
                    theme="auris-admin"
                    :columns="columns"
                    :rows="pupils"
                    :totalRows="totalRecords"
                    mode="remote"
                    @on-per-page-change="onPerPageChange"
                    :pagination-options="{
                      enabled: true,
                      mode: 'pages',
                      perPage: parseInt(rowsPerPage),
                      position: 'bottom',
                      perPageDropdown: rowsPerPageOptions,
                      dropdownAllowAll: false,
                      setCurrentPage: 1,
                      nextLabel: 'next',
                      prevLabel: 'prev',
                      rowsPerPageLabel: 'Rows per page',
                      ofLabel: 'of',
                      pageLabel: 'page', // for 'pages' mode
                      allLabel: 'All',
                    }"
                    :search-options="{
                      enabled: true,
                      trigger: 'key-up',
                      skipDiacritics: true,
                      placeholder: 'Search this table',
                    }"
                    :isLoading.sync="isLoading"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-search="onSearch"
                  >
                  <template slot="table-row" slot-scope="props">
                    <a class="u-hover__pointer" v-if="props.column.field == 'name'" @click="goToPupilPage(props.row.id)">
                      {{props.formattedRow[props.column.field]}}
                    </a>
                    <span v-else-if="props.column.field == 'actions' && userIsAdmin">
                      <button class="btn btn-link btn-icon-book js-assign" :data-name="props.row.name" :data-id="props.row.id" @click="goToAssignBooksPage(props.row.id)"><i class="icon icon-book"></i> Manage books</button>
                      <button class="btn btn-link btn-icon-trash js-delete" :data-name="props.row.name" :data-id="props.row.id" @click="startDeletePupil(props.row)"><i class="icon icon-trash"></i>Delete</button>
                    </span>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                  <template slot="loadingContent">
                    <div class="loadingContent">
                        <i class="fa fa-refresh fa-spin"></i>
                    </div>
                  </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import Multiselect from 'vue-multiselect';
import CurrentUserMixins from '@/components/mixins/CurrentUserMixins';
import SchoolMixin from '@/components/mixins/SchoolMixins';
import TableMixin from '@/components/mixins/TableMixin';
import config from '@/config';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/components/classes/Modal';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import _ from 'lodash';

export default {
  name: 'SchoolPupils',
  data() {
    return {
      previousSearchQuery: null,
      searchPerformed: null,
      group: {},
      response: {},
      originalPupils: [],
      pupils: [],
      dataTableOptions: {},
      isLoading: false,
      responsive: true,
      school: {
        name: '',
      },
      groups: [],
      selectedGroups: [],
      modal: new Modal({
        delete: false,
      }),
      pupilToDelete: {
        name: null,
        id: null,
      },
      totalRecords: 0,
      columns: [
        {
          label: 'Pupil',
          field: 'name',
          sortable: true,
        },
        {
          label: 'Class',
          field: 'group',
          sortable: true,
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          tdClass: 'actions',
        },
      ],
      serverParams: {
        columnFilters: {
        },
        sort: [
          {
            field: 'name', // example: 'name'
            type: 'asc', // 'asc' or 'desc'
          },
          {
            field: 'group',
            type: 'asc',
          },
        ],
        user_type: 'standard',
        page: 1, // what page I want to show
        perPage: this.rowsPerPage, // how many items I'm showing per page
        // filter: [],
        exclude: 'auris',
        is_ajax: 1,
        groups: [],
      },
    };
  },
  mixins: [CurrentUserMixins, SchoolMixin, TableMixin],
  mounted() {
    this.school = this.getUserSchool();
    this.$nextTick(() => {
      this.getSchool();
      this.loadItems();
    });
  },
  methods: {
    onSearch: _.debounce(function (e) {
      if (e.searchTerm !== this.previousSearchQuery && e.searchTerm !== '') {
        this.searchPerformed = true;
        this.updateParams({ search: e.searchTerm });
        this.loadItems();
      } else {
        this.searchPerformed = false;
        this.loadItems();
      }
      this.previousSearchQuery = e.searchTerm;
    }, 500),
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.handlePerPageChange(params);
      this.updateParams({ page: 1 });
      this.loadItems();
    },
    getSchool() {
      this.$http.get(`/enterprises/${this.school.id}`)
        .then((response) => {
          this.groups = this.extractGroupsFromSchool(response.data.enterprise);
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },
    getFromServer() {
      const request = axios.get(this.ajaxUrl);
      return request
        .then((response) => (response))
        .catch((e) => {
          throw e;
        });
    },
    handleCloseModal() {
      this.pupilToDelete.id = null;
      this.pupilToDelete.name = null;
      this.modal.hide('delete');
    },
    loadItems() {
      this.isLoading = true;
      this.getFromServer(this.serverParams).then((response) => {
        this.totalRecords = response.data.meta.total_filtered;
        // Add subscription description for users with no subscription
        this.pupils = response.data.readers;
        this.isLoading = false;
      });
    },
    addGroupSearchTerms(value) {
      this.serverParams.groups.push(value);
      this.loadItems();
    },
    removeGroupSearchTerms(value) {
      this.serverParams.groups = this.serverParams.groups.filter((g) => g !== value);
      this.loadItems();
    },
    goToPupilPage(e) {
      this.$router.push(`/pupils/${e}`);
    },
    goToAssignBooksPage(e) {
      this.$router.push(`/pupils/${e}/books`);
    },
    startDeletePupil(pupil) {
      this.pupilToDelete.id = pupil.id;
      this.pupilToDelete.name = pupil.name;
      this.modal.show('delete');
    },
    deletePupil() {
      this.$http.delete(`/readers/${this.pupilToDelete.id}`)
        .then(() => {
          this.loadItems();
          this.modal.hide('delete');
          this.pupilToDelete.id = null;
          this.pupilToDelete.name = null;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
          this.modal.hide('delete');
          this.pupilToDelete.id = null;
          this.pupilToDelete.name = null;
        });
    },
  },
  components: {
    Multiselect,
    modal: ModalJS,
    VueGoodTable,
  },
  computed: {
    userIsAdmin() {
      return this.$store.state.user.enterprise_user_role === 'admin';
    },
    ajaxUrl() {
      const url = new URL(`${config.serverURI}enterprises/${this.school.id}/readers`);

      const params = new URLSearchParams();
      params.set('page', this.serverParams.page);
      params.set('per_page', this.rowsPerPage);
      params.set('is_ajax', this.serverParams.is_ajax);
      if (this.searchPerformed) {
        params.set('search', this.serverParams.search);
      }
      if (this.serverParams.groups.length > 0) params.set('groups', this.serverParams.groups.toString());

      // Add the sort by columns
      this.serverParams.sort.forEach((o) => {
        params.set(`sort[${o.field}]`, o.type);
      });
      url.search = params;
      return url.toString();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .button-locator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin-left: 7.5%;
  }
</style>
