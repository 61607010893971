<template>
  <div class="vimeo-player">
    <iframe title="vimeo-player" :src="source" frameborder="0" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'vimeo-player',
  props: {
    videoId: {
      required: true,
    },
    h: {
      required: true,
    },
  },
  computed: {
    source() {
      return `https://player.vimeo.com/video/${this.videoId}?h=${this.h}`;
    },
  },
};
</script>

<style scoped lang="scss">
.vimeo-player {
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
