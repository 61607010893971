<template>
  <section class="content video-guides">
    <h2 class="bar">Every day tasks</h2>
    <div class="flexbox-grid-container u-margin-top-35">
      <div class="video-container" v-for="(video, i) in videos.section1" :key="i">
        <vimeo-player :video-id="video.id" :h="video.h" />
      </div>
    </div>
    <h2 class="bar">Set up</h2>
    <div class="flexbox-grid-container u-margin-top-35">
      <div class="video-container" v-for="(video, i) in videos.section2" :key="i">
        <vimeo-player :video-id="video.id" :h="video.h" />
      </div>
    </div>
  </section>
</template>

<script>
import VimeoPlayer from '@/components/utils/VimeoPlayer';

export default {
  name: 'video-guides',
  components: {
    VimeoPlayer,
  },
  data() {
    return {
      videoID: 'https://vimeo.com/745505950/59ecc20677',
      videos: {
        section1: [
          {
            id: '745761707',
            h: '72eb40f6ad',
          },
          {
            id: '745761352',
            h: 'a79d88df70',
          },
          {
            id: '745723837',
            h: '3f0dd2837f',
          },
          {
            id: '745522381',
            h: 'cd4d007b53',
          },
          {
            id: '755098122',
            h: '666379c914',
          },
        ],
        section2: [
          {
            id: '745521357',
            h: 'b2f5191da0',
          },
          {
            id: '745365511',
            h: 'c3dbd37d8e',
          },
          {
            id: '745505950',
            h: '59ecc20677',
          },
          {
            id: '745723975',
            h: '93dc3946a6',
          },
          {
            id: '745724005',
            h: '37f23c34a0',
          },
          {
            id: '745510178',
            h: '32c9a2e85a',
          },
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss">
.video-guides {
  .video-container {
    //width: calc(100% * (1/3) - 10px - 1px);
    width: 33%;
    .vimeo-player {
      width: 98%;
      aspect-ratio: 16/9;
    }
  }
  .flexbox-grid-container {
    justify-content: normal;
    row-gap: 20px;
  }
}
</style>
