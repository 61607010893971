export default {
  methods: {
    getTeachers(schoolId) {
      return new Promise((resolve, reject) => {
        this.$http.get(`enterprises/${schoolId}`)
          .then((response) => {
            resolve(response.data.enterprise.users);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
    isClassTeacher(group) {
      return group.user_manages;
    },
  },
};
