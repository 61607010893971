<template>
  <section class="content" v-if="!isLoading">
    <div class="d-flex align-items-start">
      <div class="book_cover_wrapper">
        <img :src="book.cover_image.replace('0x200', '0x400')" class="img-fill-width" />
        <div class="overlay">
          <a :href="`https://d3vlekh17wu64n.cloudfront.net/book_pdfs/${book.id}.pdf`" target="_blank" class="btn btn-primary btn-primary-yellow"><i class="icon icon-search-light"></i> Preview book</a>
        </div>
      </div>
      <div class="ms-5 d-flex flex-column align-items-start">
        <h1 class="mt-0 mb-4">{{book.title}}</h1>
        <p class="mb-4" style="white-space: pre-wrap">{{book.description}}</p>
        <div class="d-flex">
          <span class="badge bg-blue mt-auto me-4">Reading Level: {{ book.reading_level }}</span>
          <span v-if="book.assigned_via === null" class="badge bg-red mt-auto me-4">Currently not assigned to pupil</span>
          <span v-if="book.assigned_via === 'group'" class="badge bg-green mt-auto me-4">Currently assigned via class</span>
          <span v-if="book.assigned_via === 'reader'"  class="badge bg-green mt-auto me-4">Currently assigned to pupil</span>
        </div>
      </div>
    </div>

    <h2 class="text-center" v-html="formatDate(selectedReadingSession.date)"></h2>

    <div class="d-flex justify-content-between">

      <div>
        <h3>Reading Statistics</h3>
        <div class="d-flex align-items-start">
          <div class="stat-box me-5">
            <span class="title">Total reading time</span>
            <span class="value" v-html="formatSecondsAsDuration(selectedReadingSession.total_reading_time, '<br />')"></span>
          </div>
          <div class="stat-box me-5" v-if="selectedReadingSession.words_correct_per_minute">
            <span class="title">Words correct per minute</span>
            <span class="value">{{ Math.ceil(selectedReadingSession.words_correct_per_minute) }}</span>
          </div>
          <div class="stat-box me-5" v-if="selectedReadingSession.has_completed_book">
            <span class="title">Accuracy</span>
            <span class="value">{{ Math.floor(selectedReadingSession.accuracy) }}%</span>
          </div>
          <div class="stat-box me-5" v-if="!selectedReadingSession.has_completed_book">
            <span class="title">Projected Accuracy <i class="fa fa-question-circle text-primary" v-tooltip="'An estimated final accuracy based on the portion of the book they have read so far'"></i></span>
            <span class="value">{{ Math.floor(selectedReadingSession.range_progressive_accuracy) }}%</span>
          </div>
          <div class="stat-box me-5">
            <div class="d-flex align-items-start">
              <div class="d-flex flex-column align-items-center me-4">
                <span class="title">Book progress</span>
                <span class="value" v-if="selectedReadingSession.has_completed_book">complete</span>
                <span class="value" v-else>Page {{selectedReadingSession.last_page}} of {{book.pages_count}}</span>
              </div>
              <book-activity-heatmap :heatmap="selectedReadingSession.heatmap"></book-activity-heatmap>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-start">
          <div style="margin-right: 7em;" v-if="selectedReadingSession.played_words.length > 0">
            <h3 class="mt-5">Words requested help with:</h3>
            <ul style="font-size: 1.3em">
              <li v-for="(w, i) in selectedReadingSession.played_words" :key="i"> {{ w || 'err' }}</li>
            </ul>
          </div>

          <div v-if="selectedReadingSession.skipped_words && selectedReadingSession.skipped_words.length > 0">
            <h3 class="mt-5">Words not turned green:</h3>
            <ul style="font-size: 1.3em">
              <li v-for="(w, i) in selectedReadingSession.skipped_words" :key="i"> {{ w || 'err' }}</li>
            </ul>
            <button class="btn btn-link" @click="modal.show('missing-words-info')">Think words are missing? click here for further infomation.</button>
          </div>
        </div>
      </div>

      <div>
        <h3>Reading History</h3>
        <div class="reading-history-table-container">
          <table class="table table-striped table-reading-history" ref="readingHistoryTable">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Date</th>
                <th>Reading Time</th>
                <th>Accuracy</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rs, i) in reading_sessions" :key="rs.id" :class="{ 'selected' : viewing_reading_session === i }" @click="viewing_reading_session = i">
                <td><button class="btn-link" @click="viewing_reading_session = i" v-if="viewing_reading_session !== i"><i class="fa fa-eye"></i></button></td>
                <td class="align-middle" v-html="formatDate(rs.date, 'Do MMMM YYYY')"></td>
                <td>{{ formatSecondsAsDuration(rs.total_reading_time, '', 1) }}</td>
                <td>{{ Math.floor(rs.has_completed_book ? rs.accuracy : rs.range_progressive_accuracy) }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>

    <!-- Modal: Missing words -->
    <modal class="modal-info" title="Missing words" v-if="modal.isVisible('missing-words-info')" @close="modal.hide('missing-words-info')" :showFooter="false">
      <p>Our system is designed to filter out words when it believes there is a high probability that the child has skipped over them. For example if an entire sentence is left unhighlighted in the book.</p>
      <p>Our aim is to provide more distilled and actionable information that is genuinely useful, instead of overwhelming you with data.</p>
    </modal>
    <!--/ Modal: Missing words -->

  </section>
</template>
<script>
// import _ from 'lodash';
// import DatePickerWithDropdown from '@/components/inputs/DatePickerWithDropdown';
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import moment from 'moment';
// import moment from 'moment';
// import { VueGoodTable } from 'vue-good-table';
// import TableMixin from '@/components/mixins/TableMixin';
import formatString from '@/utils/format-string';
import { findIndex } from 'lodash';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/components/classes/Modal';
import BookActivityHeatmap from '@/components/partials/BookActivityHeatmap';

export default {
  name: 'pupil',
  mixins: [CurrentUserMixin],
  components: {
    BookActivityHeatmap,
    modal: ModalJS,
  },
  data() {
    return {
      readerId: null,
      bookId: null,
      isLoading: false,
      readerName: '',
      book: null,
      reading_sessions: [],
      viewing_reading_session: null,
      modal: new Modal({
        missingWordsInfo: false,
      }),
    };
  },
  created() {
    this.$store.dispatch('clearCurrentPupil');
  },
  beforeMount() {
    this.readerId = this.$route.params.id;
    this.bookId = this.$route.params.bookId;
    this.isLoading = true;

    const P1 = this.$http.get(`enterprises/${this.getUserSchool().id}/readers/${this.readerId}`)
      .then((response) => {
        this.readerName = response.data.reader.name;
        this.userCanUpdate = response.data.reader.can_update;
        this.$store.dispatch('setCurrentPupil', { name: this.readerName, id: this.readerId });
      })
      .catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      });

    const P2 = this.$http.get(`enterprises/${this.getUserSchool().id}/readers/${this.readerId}/books/${this.bookId}/summary`)
      .then((response) => {
        this.book = response.data.book;
        this.$store.dispatch('setCurrentBook', { title: this.book.title, id: this.book.id });
        this.reading_sessions = response.data.reading_sessions || [];

        if (this.$route.query.rid) {
          const showSessionIndex = findIndex(this.reading_sessions, { id: this.$route.query.rid });
          this.viewing_reading_session = showSessionIndex !== -1 ? showSessionIndex : (this.reading_sessions.length > 0 ? 0 : null);
        } else {
          this.viewing_reading_session = this.reading_sessions.length > 0 ? 0 : null;
        }
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      });

    Promise.all([P1, P2]).then(() => {
      this.setPageTitle(this.readerName, this.book.title);
      this.isLoading = false;

      // Scroll the reading history table to show the selected session
      this.$nextTick(() => {
        const tbody = this.$refs.readingHistoryTable.querySelector('tbody');
        const row = tbody.querySelector('tr.selected');
        row.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    });
  },
  destroyed() {
    this.$store.dispatch('clearCurrentBook');
  },
  methods: {
    formatSecondsAsDuration: formatString.formatSecondsAsDuration,
    formatDate(value, format = 'dddd Do MMMM YYYY - h:mma') {
      return moment(value).format(format).replace(/(\d)(st|nd|rd|th)/g, '$1<sup>$2</sup>');
    },
  },
  computed: {
    selectedReadingSession() {
      return this.viewing_reading_session !== null && this.reading_sessions[this.viewing_reading_session] !== undefined ? this.reading_sessions[this.viewing_reading_session] : false;
    },
  },
  watch: {
    viewing_reading_session() {
      if (this.$route.query && this.$route.query.rid && this.$route.query.rid !== this.selectedReadingSession.id) {
        this.$router.replace({ query: { rid: this.selectedReadingSession.id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .stat-box {
    display: flex;
    flex-direction: column;
    background: white;
    //box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    padding: 10px 15px;
    align-items: center;
    border: 2px solid #1e8ea7;
    line-height:1;
    .title {
      margin-bottom:0.5em;
    }
    .value {
      font-size: 2em;
      font-weight: 500;
    }
  }

  .reading-history-table-container {
    max-height: 500px;
    overflow: scroll;
    > table > thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;
      box-shadow: 0px 1px 0px rgba(0,0,0,0.3);
    }
  }

  .table-reading-history {
    margin: 0;
    tr.selected > td {
      background: #EBF5F8;
      // font-weight: 600;
      text-shadow: -0.03ex 0 currentColor, 0.03ex 0 currentColor; // Creates a fake bold without affecting the width of the element
    }
    tr > td { vertical-align: middle; user-select: none; cursor: pointer;}
  }

  .book_cover_wrapper {
    position: relative;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
    width:300px;
    height:300px;
    > .overlay {
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.15s;
      // Yep - This is stupid!
      a.btn {
        height: auto;
        padding-top:0.2em;
        padding-bottom: 0.2em;
        transform: translateY(-2em);
        transition: transform 0.25s;
      }

      &:hover {
        opacity: 1;
        a.btn { transform: translateY(0); }
      }

    }
  }
</style>
