<template>
  <div class="u-margin-top-65 u-margin-bottom-65 flexbox-grid-container ">
    <grid-item
      title="Templates"
      iconClass="icon-templates-dark"
      :hasViewAll="false"
      :withArrow="false"
    >
      <div class="template-group">
        <h3 class="template-group-title">Upload files</h3>
        <div class="template-group-body">
          <div class="template-description-container">
            <h4 class="template-title">Pupil import</h4>
            <p class="template-description">Use this template to bulk create new pupil accounts.</p>
          </div>
          <div class="template-button-container">
            <download-button
              href="https://s3-eu-west-1.amazonaws.com/auris-api-exports/pupils_example.xlsx"
              suggestedDownloadFileName="Pupils Template"
            />
          </div>
        </div>
        <div class="template-group-body">
          <div class="template-description-container">
            <h4 class="template-title">Teacher import</h4>
            <p class="template-description">Use this template to bulk invite new teachers</p>
          </div>
          <div class="template-button-container">
            <download-button href="https://s3-eu-west-1.amazonaws.com/auris-api-exports/teachers_example.xlsx" suggestedDownloadFileName="Teacher Template"/>
          </div>
        </div>
      </div>
    </grid-item>
    <grid-item
      title="Certificates"
      iconClass="icon-certificate-dark"
      :hasViewAll="false"
      :withArrow="false"
    >
      <div class="template-group">
        <h3 class="template-group-title">Encourage your students</h3>
        <div class="template-group-body">
          <div class="template-description-container">
            <h4 class="template-title">Book Worm</h4>
            <!-- <p class="template-description">Use this certificate to reward enthusiatic readers</p> -->
          </div>
          <div class="template-button-container">
            <download-button
              href="/files/certificates/book-worm.pdf"
              suggestedDownloadFileName="FONETTI - Book Worm Certificate"
            />
          </div>
        </div>
        <div class="template-group-body">
          <div class="template-description-container">
            <h4 class="template-title">Class of the Week</h4>
            <!-- <p class="template-description">Use this certificate to reward your class of the week</p> -->
          </div>
          <div class="template-button-container">
            <download-button
              href="/files/certificates/class-of-the-week.pdf"
              suggestedDownloadFileName="FONETTI - Class of the Week Certificate"
            />
          </div>
        </div>
         <div class="template-group-body">
          <div class="template-description-container">
            <h4 class="template-title">Improved Reader</h4>
            <!-- <p class="template-description">Use this certificate to reward the most improved reader</p> -->
          </div>
          <div class="template-button-container">
            <download-button
              href="/files/certificates/improved-reader.pdf"
              suggestedDownloadFileName="FONETTI - Improved Reader Certificate"
            />
          </div>
        </div>
         <div class="template-group-body">
          <div class="template-description-container">
            <h4 class="template-title">Next Level Reader</h4>
            <!-- <p class="template-description">Use this certificate to reward students who have completed a level</p> -->
          </div>
          <div class="template-button-container">
            <download-button
            href="/files/certificates/next-level-reader.pdf"
            suggestedDownloadFileName="FONETTI - Next Level Reader Certificate"/>
          </div>
        </div>
         <div class="template-group-body">
          <div class="template-description-container">
            <h4 class="template-title">Reader of the Week</h4>
            <!-- <p class="template-description">Use this certificate the reward your reader of the week</p> -->
          </div>
          <div class="template-button-container">
            <download-button
              href="/files/certificates/reader-of-the-week.pdf"
              suggestedDownloadFileName="FONETTI - Reader of the Week Certificate"
            />
          </div>
        </div>
         <div class="template-group-body">
          <div class="template-description-container">
            <h4 class="template-title">Superstar Reader</h4>
            <!-- <p class="template-description">Use this certificate to reward your superstar readers</p> -->
          </div>
          <div class="template-button-container">
            <download-button
              href="/files/certificates/superstar-reader.pdf"
              suggestedDownloadFileName="FONETTI - Superstar Reader Certificate"
            />
          </div>
        </div>
      </div>
    </grid-item>
  </div>
</template>

<script>
import GridItem from '@/components/partials/GridItem';
import DownloadButton from '@/components/inputs/DownloadButton';

export default {
  name: 'Templates',
  components: { GridItem, DownloadButton },
};
</script>

<style lang="scss" scoped>
  .template-group {
    margin: 30px 20px;
  }

  .template-group-title {
    font-size: 1.3em;
    font-weight: 600;
    color: #606265;
    margin-bottom: 5px;
  }

  .template-title {
    font-size: 1.2em;
    color: #6A6366;
    font-weight: 400;
    margin-top: 20px;
  }

  .template-description {
    font-size: 1em;
    font-weight: 200;
    color: #9999A0;
  }

  .template-group-body {
    display: flex;
    justify-content: space-between;
  }

  .template-button-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 120px;

    button:first-child {
      margin-top: 20px;
    }

    a:first-child {
      margin-top: 20px;
    }

    button:not(:first-child) {
      margin-top: 5px;
    }

    a:not(:first-child) {
      margin-top: 5px;
    }
  }
</style>
