export default class Form {
  constructor(data) {
    this.originalData = data;
    // eslint-disable-next-line
    for (const field in data) {
      this[field] = data[field];
    }
  }

  post(url) {
    return new Promise((resolve, reject) => {
      this.submit('post', url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  data() {
    const data = {};
    // eslint-disable-next-line
    for (const field in this.originalData) {
      data[field] = this[field];
    }
    return data;
  }

  submit(method, url) {
    // this.$http is undefined as this is not a $vm mixin.
    // I would throw new Error here, but ESLint shouts at me.
    console.error('Form.submit is no longer supported');
    return new Promise((resolve, reject) => {
      this.$http[method](url, this.data())
        .then((response) => {
          Form.onSuccess(response.data);
          resolve(response);
        })
        .catch((error) => {
          Form.onFailure(error);
          reject(error);
        });
    });
  }

  static onSuccess() {
    return true;
  }

  static onFailure() {
    return true;
  }
}
