import _ from 'lodash';

export default {
  methods: {
    getChart(chart) {
      this.isLoading = true;
      let url;
      if (chart.groupId) {
        url = `/charts?group_id=${chart.groupId}&name=${chart.name}&start_date=${chart.startDate.toISOString()}&end_date=${chart.endDate.toISOString()}`;
      } else if (chart.readerId) {
        url = `/charts?name=${chart.name}&reader_id=${chart.readerId}&start_date=${chart.startDate.toISOString()}&end_date=${chart.endDate.toISOString()}`;
      }
      if (chart.output) {
        url = `${url}&output=${chart.output}`;
      }
      return new Promise((resolve, reject) => {
        this.$http.get(url)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
    fetchDataForCharts(charts) {
      _.forEach(charts, (chart) => {
        chart.fetchData()
          .then(() => {
            this.$nextTick(() => {
              chart.render();
            });
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
          });
      });
    },

    stopLoading() {
      this.isLoading = false;
    },

    tooltipReadingTime(value) {
      let label = '';
      label += label ? ': ' : '';
      if (value < 60 * 2) {
        return `${parseInt(value, 10)} secs`;
      }
      const mins = Math.round(value / 60);
      if (mins < 60) {
        return `${mins} mins`;
      }
      const hours = Math.floor(mins / 60);
      label += hours > 0 ? hours + (hours > 1 ? ' hrs ' : ' hr ') : '';
      return label;
    },

    tooltipConversionRate(y, series) {
      if (series.seriesIndex === 2) {
        return `${y}%`;
      }

      return y;
    },

    percentageFormatter(value) {
      return `${parseInt(value, 10)}%`;
    },
    totalBooksFormatter(value) {
      const val = parseInt(value, 10);
      if (val === 1) {
        return `${val} book`;
      }
      return `${val} books`;
    },

    yAxisReadingTime(value) {
      let label = '';
      label += label ? ': ' : '';
      if (value < 60 * 2) {
        return `${parseInt(value, 10)} secs`;
      }
      const mins = Math.round(value / 60);
      if (mins < 60) {
        return `${mins} mins`;
      }
      const hours = Math.floor(mins / 60);
      label += hours > 0 ? hours + (hours > 1 ? ' hrs ' : ' hr ') : '';
      return label;
    },

    yAxisInteger(value) {
      return value.toFixed(0);
    },
  },
};
