export default {
  methods: {
    createPupils(pupils, schoolId, groupId = null) {
      let successMessage = 'The pupils have been added to the school';
      let url = `enterprises/${schoolId}/readers`;
      let postData = {};
      if (groupId !== null && groupId !== '') {
        url = `enterprises/${schoolId}/groups/${groupId}/readers`;
        postData = { readers: this.formatGroupPupilsForAPI(pupils) };
        successMessage = 'The pupils have been added to the class';
      } else {
        postData = { readers: this.formatSchoolPupilsForAPI(pupils) };
      }
      return new Promise((resolve, reject) => {
        this.$http.post(url, postData)
          .then((response) => {
            this.$apiResponse.renderSuccessMessage(response, successMessage);
            if (response.data.links) {
              resolve(response.data.links);
            }
            resolve(null);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
    formatGroupPupilsForAPI(pupils) {
      const existingPupils = [];
      const newPupils = [];
      this._.each(pupils, (pupil) => {
        if (pupil.id) {
          existingPupils.push(pupil.id);
        } else if (!this._.isEmpty(pupil.name)) {
          newPupils.push(pupil);
        }
      });

      return {
        existing: existingPupils,
        new: newPupils,
      };
    },
    formatSchoolPupilsForAPI(pupils) {
      return this._.filter(pupils, (pupil) => !this._.isEmpty(pupil.name));
    },
    formatLinks(links) {
      if (this._.isEmpty(links)) {
        return false;
      }
      let list = '<ul>';
      const elements = this._.map(links, (link, index) => `<li><a href="${link}">${index}</a></li>`);
      list += this._.join(elements, '');
      list += '</ul>';
      return list;
    },
    closeLinksModal() {
      if (!this.$store.state.termsAndConditionsAccepted) {
        this.termsAndConditionsMissing = true;
        return;
      }
      this.termsAndConditionsMissing = false;
      this.$store.commit('ACCEPT_TERMS_AND_CONDITIONS', false);
      this.modal.hide('links');
    },
  },
};
