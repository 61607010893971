<template>
  <div class="flexbox-grid-container u-margin-top-65">
    <grid-item v-for="section in sections" :key="section.id"
      :title="section.title"
      :iconClass="section.iconClass"
      :hasViewAll="section.viewAll.hasViewAll"
      :hideViewAllText="section.viewAll.hideText"
      :viewAllUrl="section.viewAll.url"
      :listItems="section.listItems"
    />
  </div>
</template>

<script>
import GridItem from '@/components/partials/GridItem';

export default {
  name: 'Overview',
  components: { GridItem },
  data() {
    return {
      sections: [
        {
          id: 1,
          title: 'USER GUIDE',
          iconClass: 'icon-user-guide-dark',
          viewAll: {
            hasViewAll: true,
            url: '/userguide',
          },
          listItems: [
            {
              text: 'Register a user',
              isLink: true,
              withButtons: false,
              url: '/userguide/a/2',
            },
            {
              text: 'Adding teachers',
              isLink: true,
              withButtons: false,
              url: '/userguide/a/3',
            },
            {
              text: 'The teacher registration process',
              isLink: true,
              withButtons: false,
              url: '/userguide/a/4',
            },
            {
              text: 'Adding pupils',
              isLink: true,
              withButtons: false,
              url: '/userguide/a/6',
            },
            {
              text: 'The teacher registration process',
              isLink: true,
              withButtons: false,
              url: '/userguide/a/4',
            },
          ],
        },

        {
          id: 2,
          title: 'TEMPLATES',
          iconClass: 'icon-templates-dark',
          viewAll: {
            hasViewAll: true,
            url: '/templates',
          },
          listItems: [
            {
              text: 'Pupil import',
              isLink: false,
              withButtons: true,
              url: '',
              buttons: {
                download: {
                  url: 'https://s3-eu-west-1.amazonaws.com/auris-api-exports/pupils_example.xlsx',
                  suggestedFileName: 'Pupils Template',
                },
                viewLink: '',
              },
            },
            {
              text: 'Teacher import',
              isLink: false,
              withButtons: true,
              url: '',
              buttons: {
                download: {
                  url: 'https://s3-eu-west-1.amazonaws.com/auris-api-exports/teachers_example.xlsx',
                  suggestedFileName: 'Teacher Template',
                },
              },
            },
            {
              text: 'Reader of the Week Certificate',
              isLink: false,
              withButtons: true,
              url: '',
              buttons: {
                download: {
                  url: '/files/certificates/reader-of-the-week.pdf',
                  suggestedFileName: 'FONETTI - Reader of the Week Certificate',
                },
              },
            },
            {
              text: 'Class of the Week Certificate',
              isLink: false,
              withButtons: true,
              url: '',
              buttons: {
                download: {
                  url: '/files/certificates/class-of-the-week.pdf',
                  suggestedFileName: 'FONETTI - Class of the Week Certificate',
                },
              },
            },
          ],
        },

        {
          id: 3,
          title: 'FAQs',
          iconClass: 'icon-faq-dark',
          viewAll: {
            hasViewAll: true,
            url: 'faq',
          },
          listItems: [
            {
              text: 'How do I delete a pupil\'s account?',
              isLink: true,
              withButtons: false,
              url: '/faq/1',
            },
            {
              text: 'How can I reset a pupil\'s password?',
              isLink: true,
              withButtons: false,
              url: '/faq/2',
            },
            {
              text: 'How do I merge two profiles together?',
              isLink: true,
              withButtons: false,
              url: '/faq/3',
            },
            {
              text: 'My child has a school Fonetti subscription, how do I link it to his or her school account?',
              isLink: true,
              withButtons: false,
              url: '/faq/4',
            },
            {
              text: 'My child has a school account but I\'d like to sign up for Fonetti, How can I sign up?',
              isLink: true,
              withButtons: false,
              url: '/faq/5',
            },
          ],
        },

        {
          id: 4,
          title: 'CONTACT',
          iconClass: 'icon-contact-dark',
          viewAll: {
            hasViewAll: true,
            url: '/contact',
            hideText: true,
          },
          listItems: [
            {
              text: '0203 004 9658',
              isLink: false,
              withButtons: false,
              isPhoneNumber: true,
            },
            {
              text: 'info@fonetti.com',
              isLink: false,
              withButtons: false,
              isEmail: true,
            },
            {
              text: 'Raise a ticket with us',
              isLink: true,
              withButtons: false,
              url: '/contact',
            },
          ],
        },
      ],
    };
  },
  methods: {
    name() {
      return 'Overview';
    },
  },
};
</script>

<style lang="scss" scoped>
  .u-margin-top-65 {
    margin-top: 65px;
  }

  .flexbox-grid-container {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-width: 100%;
    min-height: 100%;
    padding: none;
    margin: none;
  }

</style>
