import formatString from '@/utils/format-string';

export default class ChartConfig {
  constructor() {
    // default config
    this.type = 'line';
    this.title = {};
    this.yAxisAnnotations = [];
    this.annotationOnYAxisText = '';
    this.labels = [];
    this.stacked = false;
    this.allowNegativeYAxis = true;
    this.labelFormatter = null;
    this.yAxisFormatter = null;
    this.colors = null;
    this.seriesTypes = {};
    this.mixedYAxis = [];
    this.dataLabels = null;
    this.mountedCallBack = null;
    this.filename = 'ChartDownload';
    this.date = null;
    this.ditributedBarCharts = false;
    this.yAxisMax = null;
    this.yAxisTickAmount = null;

    this.toolbar = {
      show: true,
      tools: {
        download: '<i class="fa fa-image"></i>',
        customIcons: [],
      },
      export: {
        csv: {
          filename: undefined,
        },
      },

    };

    this.xAxis = {
      categories: [],
    };

    this.defaultFontFamily = 'Source Sans Pro, Helvetica, Arial, sans-serif';
    this.defaultFontSize = '14px';
    this.displayAsTimeSeries = false;
  }

  setYAxisMax(n) {
    this.yAxisMax = n;
    return this;
  }

  setYAxisTickAmount(n) {
    this.yAxisTickAmount = n;
    return this;
  }

  setDistributedBarCharts(boolean) {
    this.ditributedBarCharts = boolean;
    return this;
  }

  setCSVFileName(className) {
    this.setDate();
    const formattedClassName = formatString.splitAndJoin({ string: className, splitBy: ' ', joinWith: '-' });
    const formattedTitleText = formatString.splitAndJoin({ string: this.title.text, splitBy: ' ', joinWith: '-' });
    this.toolbar.export.csv.filename = `${formattedClassName}-${formattedTitleText}-${this.date}`;
    return this;
  }

  setChartId(className) {
    this.setDate();
    const formattedClassName = formatString.splitAndJoin({ string: className, splitBy: ' ', joinWith: '' });
    const formattedTitleText = formatString.splitAndJoin({ string: this.title.text, splitBy: ' ', joinWith: '' });
    const formattedDate = formatString.splitAndJoin({ string: this.date, splitBy: '-', joinWith: '' });
    this.chartId = `${formattedClassName}${formattedTitleText}${formattedDate}`;
    return this;
  }

  setDate() {
    const today = new Date();
    const dd = String(today.getDate());
    const mm = String(today.getMonth() + 1);
    const yyyy = String(today.getFullYear());
    this.date = `${dd}-${mm}-${yyyy}`;
    return this;
  }

  isType(type) {
    this.type = type;
    return this;
  }

  isTimeSeries() {
    this.displayAsTimeSeries = true;
    return this;
  }

  isStacked() {
    this.stacked = true;
    return this;
  }

  withCustomTool(tool) {
    this.toolbar.tools.customIcons.push(tool);
    return this;
  }

  withAnnotationOnYAxis(text) {
    this.annotationOnYAxisText = text;

    return this;
  }

  withLabelFormatter(formatter) {
    this.labelFormatter = formatter;
    return this;
  }

  withToolTipFormatter(formatter) {
    this.toolTipFormatter = formatter;
    return this;
  }

  withYAxisFormatter(formatter) {
    this.yAxisFormatter = formatter;
    return this;
  }

  disableNegativeYAxis() {
    this.allowNegativeYAxis = false;
    return this;
  }

  setColors(colors) {
    this.colors = colors;
    return this;
  }

  setAnnotationOnYaxisValue(value, index = 0) {
    const annotation = {
      y: value,
      y2: null,
      strokeDashArray: [8, 4],
      borderColor: '#775DD0',
      label: {
        borderColor: '#775DD0',
        style: {
          color: '#fff',
          background: '#775DD0',
        },
        text: `${this.annotationOnYAxisText}: ${this.yAxisFormatter(value)}`,
      },
    };
    this.yAxisAnnotations[index] = annotation;
  }

  getYaxisAnnotation(index = 0) {
    return this.yAxisAnnotations[index];
  }

  setLabels(labels) {
    this.labels = labels;
    this.xAxis.categories = labels;
  }

  setYAxis(data) {
    this.mixedYAxis.push(data);
    return this;
  }

  withTitle(title) {
    this.title = {
      text: title,
      align: 'left',
      style: {
        fontSize: 22,
      },
    };
    return this;
  }

  setSeries(series) {
    this.series = series;
  }

  setDataLabels(dataLabels) {
    this.dataLabels = dataLabels;
    return this;
  }

  setMountedCallBack(fn) {
    this.mountedCallBack = fn;
    return this;
  }

  setUpdatedCallBack(fn) {
    this.updatedCallBack = fn;
    return this;
  }

  get() {
    // let's construct the config object
    const options = {};
    options.series = this.series;
    options.chart = {
      id: this.chartId,
      type: this.type,
      height: 350,
      stacked: this.stacked,
      width: '100%',
      fontFamily: this.defaultFontFamily,
      toolbar: this.toolbar,
      shadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      events: {
        mounted() {},
        updated() {},
      },
    };
    if (this.type === 'mixed') {
      options.chart.type = 'line';
    }

    if (this.mountedCallBack) {
      options.chart.events.mounted = this.mountedCallBack;
    }

    if (this.updatedCallBack) {
      options.chart.events.updated = this.updatedCallBack;
    }

    if (this.dataLabels) {
      options.dataLabels = this.dataLabels;
    } else {
      options.dataLabels = {
        enabled: true,
        style: {
          fontSize: this.defaultFontSize,
        },
      };
    }

    if (this.type === 'mixed') {
      options.dataLabels.enabled = false;
    }
    if (this.labelFormatter) {
      options.dataLabels.formatter = this.labelFormatter;
    }
    options.markers = {
      size: 4,
    };
    options.title = this.title;
    options.stroke = {
      curve: 'smooth',
    };
    if (this.type === 'mixed') {
      options.stroke.width = [1, 1, 4];
    }
    options.yaxis = {
      labels: {
        hideOverlappingLabels: false,
        minWidth: 45, /* see: https://auris-tech.codebasehq.com/projects/fonetti-portal/discussions/why-did-lee-set-minwidth-on-the-school-portal-yaxis */
        style: {
          fontSize: this.defaultFontSize,
          fontFamily: this.defaultFontFamily,
        },
      },
    };

    if (this.yAxisMax) {
      options.yaxis.max = this.yAxisMax;
    }

    if (this.yAxisTickAmount) {
      options.yaxis.tickAmount = this.yAxisTickAmount;
    }

    if (this.type === 'mixed') {
      const yAxis = [];
      this.mixedYAxis.forEach((mixedYAxis) => {
        if (mixedYAxis.name === undefined) {
          mixedYAxis.name = mixedYAxis.title;
        }
        if (mixedYAxis.decimal === undefined) {
          mixedYAxis.decimal = 0;
        }
        if (mixedYAxis.show === undefined) {
          mixedYAxis.show = true;
        }
        const y = {
          show: mixedYAxis.show,
          seriesName: mixedYAxis.name,
          opposite: mixedYAxis.opposite,
          decimalsInFloat: mixedYAxis.decimal,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: mixedYAxis.color,
          },
          labels: {
            style: {
              colors: mixedYAxis.color,
            },
          },
          title: {
            text: mixedYAxis.title,
            style: {
              color: mixedYAxis.color,
            },
          },
          tooltip: {
            enabled: true,
          },
        };
        yAxis.push(y);
      });
      options.yaxis = yAxis;
    }

    if (this.yAxisFormatter) {
      options.yaxis.labels.formatter = this.yAxisFormatter;
    }
    if (!this.allowNegativeYAxis) {
      options.yaxis.min = 0;
    }
    options.grid = {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    };
    options.plotOptions = {
      bar: {
        distributed: this.ditributedBarCharts,
        dataLabels: {
          position: 'top',
        },
      },
    };
    if (this.stacked) {
      options.plotOptions.bar.dataLabels.total = {};
      options.plotOptions.bar.dataLabels.total.enabled = true;
    }
    options.tooltip = {
      enabled: true,
      y: {
        formatter: this.toolTipFormatter,
      },
    };
    if (this.type === 'mixed') {
      options.tooltip = {
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetY: 30,
          offsetX: 60,
        },
        y: {
          formatter: this.toolTipFormatter,
        },
      };
    }
    options.noData = {
      text: 'There is nothing here',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: this.defaultFontSize,
        fontFamily: this.defaultFontFamily,
      },
    };
    options.labels = this.labels;
    options.legend = {
      showForSingleSeries: true,
      showForZeroSeries: true,
      onItemClick: {
        toggleDataSeries: false,
      },
    };

    // specific config if timeseries
    if (this.displayAsTimeSeries) {
      options.chart.zoom = {
        type: 'x',
        enabled: false,
        autoScaleYaxis: true,
      };
      options.chart.toolbar.autoSelected = 'zoom';
      options.xaxis = {
        type: 'datetime',
      };
    } else {
      options.xaxis = this.xAxis;
      if (this.type === 'mixed') {
        options.xaxis = {
          categories: this.xAxis,
        };
      }
    }

    const pluck = (arr, key) => arr.map((o) => o[key]);
    if (this.colors !== null) {
      const names = pluck(this.series, 'name');
      options.colors = [];
      names.forEach((n) => {
        if (n in this.colors) {
          options.colors.push(this.colors[n]);
        } else {
          options.colors.push('#d9d9d9');
        }
      });
    }
    return options;
  }
}
