<template>
  <div>
    <ul class="tabs">
      <li class="tab" :class="{isActive: tab.isActive}" v-for="(tab, index) in tabs" :key="tab.title" @click="selectTab(index)"><i class="icon-mask" :class="tab.iconClass"></i>{{ tab.title }}</li>
    </ul>
    <slot>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  data() {
    return {
      selectedIndex: 0,
      tabs: [],
    };
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(0);
  },
  methods: {
    selectTab(i) {
      this.$emit('tabSelected', i);
      this.selectedIndex = i;

      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .tabs {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    margin: 65px 30px 0 30px;
    padding: 0;
  }

  .tab {
    border-left: 1px solid #939797;
    border-top: 1px solid #939797;
    border-right: 1px solid #939797;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 33%;
    padding: 20px;
    margin: 0;
    background: #F2F2F2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 65px;
    font-size: 1.250em;
    color: #757575;
    position: relative;
  }

  .tab.isActive {
    background: #EBF5F8;
    margin-bottom: none;
    z-index: 100;

  }

  .tab.isActive::after {
    content: '';
    width: 100%;
    height: 1px;
    background: #EBF5F8;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .icon-mask {
    display: inline-block;
    text-rendering: auto;
    width: 30px;
    margin-right: 15px;

  }
</style>
