<template>
  <div class="grid-item grid-item__overview" :class="{'hidden-visually': !isVisible, 'with-arrow': withArrow}">
      <div class="fb-grid-box-header" :class="headerClass">
        <div class="box-title__with-icon">
          <i class="icon-mask" :class="iconClass"></i>
          <h2 >{{ title }}</h2>
        </div>
        <router-link :to="viewAllUrl || ''"><button v-if="hasViewAll" class="btn btn-view-all"><span v-if="!hideViewAllText">View all</span><i class="icon icon-view-all-arrow"></i></button></router-link>
      </div>
      <div class="fb-grid-box-body">
        <ul v-if="!!listItems" class="links-list">
          <li v-for="item in listItems" :key="item.id" :class="{'has-buttons' : item.withButtons}">
            <router-link v-if="item.isLink" :to="item.url || ''">{{item.text}}</router-link>
            <contact-link :isPhoneNumber="item.isPhoneNumber" :isEmail="item.isEmail" :text="item.text"/>
            <div v-if="item.withButtons" class="download-button-container">
              <span>{{item.text}}</span>
              <span class="button-locator">
                <download-button :smallFont="true" :href="item.buttons.download.url" :suggestedDownloadFileName="item.buttons.download.suggestedFileName"/>
              </span>
            </div>
          </li>
        </ul>
        <slot>
        </slot>
      </div>
    </div>
</template>

<script>

import ContactLink from '@/components/partials/ContactLink';
import DownloadButton from '@/components/inputs/DownloadButton';

export default {
  name: 'grid-item',
  components: { ContactLink, DownloadButton },
  props: {
    title: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      required: true,
    },
    headerClass: {
      type: String,
      required: false,
    },
    hasViewAll: {
      type: Boolean,
      required: true,
    },
    hideViewAllText: {
      type: Boolean,
      default: false,
    },
    viewAllUrl: {
      type: String,
      required: false,
    },
    listItems: {
      type: Array,
      required: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    withArrow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .grid-item {
    display: flex;
    width: calc(50% - 45px); // 45 = half total gutter width
    flex-direction: column;
  }

  .grid-item:nth-child(n + 3) {
    margin-top: 30px;
  }

  .grid-item__overview {
    border-radius: 10px;
    border: 1px solid #757575;
  }

  .fb-grid-box-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    background: #EBF5F8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    h2 {
      margin: 0 20px 0 0;
    }
  }

  .fb-grid-box-header.fb-grid-box-header__green {
    background-color: #2194B0;

    h2 {
      font-size: 1.250em;
      font-weight: 400;
      color: #fff;
    }
  }

  .box-title__with-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
    height: 65px;

    h2 {
      font-size: 1.250em;
      font-weight: 400;
      color: #757575;
    }
  }

  .icon-mask {
    display: inline-block;
    text-rendering: auto;
    color: inherit;
    width: 30px;
    margin-right: 20px;
  }

  .icon__right {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    width: 30px;
    margin-right: 0;
  }

  .icon::before {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: center;
    min-width: 30px;
  }

  .fb-grid-box-body {
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 100%;
  }

  .btn.btn-view-all {
    background: none;
    font-size: 0.875em;
    font-weight: 400;
    color: #757575;
    display: flex;
    align-items: center;
    .icon {
      margin-left: 25px;
      height: 17px;
    }
  }

  .btn.btn-view-all:active {
    box-shadow: none;
  }

  .download-button-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .button-locator {
      display: flex;
      flex-direction: row;
      width: 230px;
      justify-content: flex-end;
      button, a {
        text-transform: none;
        height: 20px;
        font-size: 0.750em;
        margin-top:3px;
        margin: 0;
        padding: 0px 10px;
        width: 120px;
        font-weight: 300;
        .icon {
          height: 0.750em;
          margin: 0;
          padding: 0;
        }
      }

      .btn-secondary {
        background-color: #22C7C3;
        color: #fff;
        width: 90px;
      }
    }
  }

  .grid-item.grid-item__overview.with-arrow::after {
    content: '';
    position: absolute;
    left: calc(50% - 15px);
    top: calc(50% - 50px);
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left:30px solid #2194B0;
    z-index: 100
  }
</style>
