export default {
  methods: {
    capitaliseFirstLetter(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      return null;
    },
    pluralize(count, unit) {
      if (count === 0 || count > 1) return `${unit}s`;
      return unit;
    },
  },
};
