<template>
  <ValidationObserver v-slot="{ passes }" ref="form">
    <form ref="observer-file" @submit.prevent="passes(formValidated)">
      <ValidationProvider :rules="{ required: true, ext: 'csv|xls|xlsx' }" ref="fileProvider" v-slot="{ errors }" name="File">
        <div class="drag-drop-file" @dragover="dragover" @dragleave="dragleave" @drop="drop">
          <input type="file" ref="fileInput" name="file" id="file" @change="fileChanged" />
          <label for="file" ref="fileLabel">
            <i class="icon icon-upload"></i> <span v-html="buttonText"></span>
          </label>
          <p v-show="errors" class="text-danger">{{ errors[0] }}</p>
        </div>
        <loading-button :is-loading="isLoading" class="btn btn-primary">Import</loading-button>
      </ValidationProvider>
      <slot></slot>
    </form>
  </ValidationObserver>
</template>

<script>
import LoadingButton from '@/components/utils/LoadingButton';

export default {
  name: 'drag-drop-file',
  props: ['isLoading'],
  components: {
    LoadingButton,
  },
  data() {
    return {
      buttonText: 'Drop a file (excel/csv) or click here',
      uploadedFile: null,
    };
  },
  methods: {
    dragover(event) {
      event.preventDefault();
      event.currentTarget.classList.add('dragged-hover');
    },
    dragleave(event) {
      event.preventDefault();
      event.currentTarget.classList.remove('dragged-hover');
    },
    drop(event) {
      event.preventDefault();
      this.uploadedFile = event.dataTransfer.files;
      const fileInput = document.getElementById('file');
      fileInput.files = event.dataTransfer.files;
      fileInput.dispatchEvent(new Event('change'));
    },
    async fileChanged(e) {
      const { valid } = await this.$refs.fileProvider.validate(e);
      // eslint-disable-next-line prefer-destructuring
      const label = this.$refs.fileLabel;
      if (valid) {
        label.querySelector('span').innerHTML = e.target.value.split('\\').pop();
      } else {
        label.querySelector('span').innerHTML = 'Drop a file (excel/csv) or click here';
      }
      this.uploadedFile = e.target.files;
    },
    formValidated() {
      this.$emit('submit', this.uploadedFile);
    },
  },
};
</script>

<style scoped>
  .drag-drop-file {
    background-color: #ecf0f5;
    padding: 20px;
    text-align: center;
    margin-bottom: 10px;
  }
  .drag-drop-file.dragged-hover {
    background-color: #e2e8ea;
    /*background-color: #e6ebef;*/
  }
  .drag-drop-file input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .drag-drop-file input + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: #ff7a00;
    display: inline-block;
    padding: 10px 20px;
    margin: 0;
    border-radius: 100px;
  }

  .drag-drop-file input + label i {
    margin-right: 10px;
  }

  .drag-drop-file input:focus + label,
  .drag-drop-file input + label:hover {
    background-color: #ff7a00;
    opacity: .6;
  }
  .drag-drop-file input + label {
    cursor: pointer;
  }
  .drag-drop-file input:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

</style>
