<template>
   <ValidationObserver v-slot="{ passes }">
    <form class="ui form login-form"  @submit.prevent="passes(validateForm)">
      <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors.length > 0 }">
          <div class="input-group">
            <span class="input-group-addon"><i class="icon icon-envelope"></i></span>
            <input class="form-control" id="email" name="email" placeholder="Email"
                  v-model="mutableValue.email"
                  data-vv-as="Email"
                  type="text">
          </div>
          <span v-show="errors.length > 0" class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>
      <loading-button :is-loading="loading" class="btn-block">Reset Password</loading-button>
    </form>
   </ValidationObserver>
</template>

<script>
import LoadingButton from '../utils/LoadingButton';
import FormMixin from '../mixins/FormMixin';

export default {
  name: 'forgotten-password-form',
  mixins: [FormMixin],
  components: { LoadingButton },
  props: ['value', 'isLoading'],
  data() {
    return {
      mutableValue: Object.assign(this.value),
    };
  },
  computed: {
    loading() {
      return this.isLoading;
    },
  },
};
</script>
