<template>
  <div class="container container-table">
  </div>
</template>
<script>
export default {
  name: 'Logout',
  created() {
    this.$store.dispatch('logout');
    this.$router.push('/login');
  },
  methods: {},
};
</script>

<style>
</style>
