<template>
  <!-- Main content -->
  <section class="content">
    <div class="row" v-if="ready">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-body">
            <school-form v-model="school" @validated="editSchool" :is-loading="isLoading"></school-form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import SchoolForm from '@/components/forms/SchoolForm';
import SchoolMixins from '@/components/mixins/SchoolMixins';
import CurrentUserMixins from '@/components/mixins/CurrentUserMixins';

export default {
  data() {
    return {
      isLoading: false,
      errorMsg: null,
      ready: false,
      schoolId: null,
      school: {},
    };
  },
  mixins: [SchoolMixins, CurrentUserMixins],
  mounted() {
    const school = this.getUserSchool();
    this.schoolId = school.id;
    this.$nextTick(() => {
      this.getSchoolData();
    });
  },
  methods: {
    getSchoolData() {
      this.getSchool(this.schoolId)
        .then((school) => {
          this.school = this.formatSchool(school);

          if (this.$authorization.isAuthorized('edit_school')) {
            this.ready = true;
          } else {
            this.$router.push('/school');
          }
        });
    },
    editSchool() {
      this.isLoading = true;
      this.$http.post(`enterprises/${this.school.id}`, { name: this.school.name, urn: this.school.urn, type: 'school' })
        .then(() => this.$http.post(`enterprises/${this.school.id}/contacts`, { contact: this.flattenContacts(this.school) }))
        .then(() => {
          this.$router.push('/school');
        })
        .catch((error) => {
          this.isLoading = false;
          this.$apiResponse.renderErrorMessage(error);
        });
    },
  },
  components: {
    SchoolForm,
    'school-form': SchoolForm,
  },
};
</script>
<style>
  legend {
    color: #333;
  }
</style>
