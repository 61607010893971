<template>
  <ValidationObserver v-slot="{ passes }">
    <form ref="observer" class="ui form subscription-contact-form form-horizontal" @submit.prevent="passes(validateForm)">
      <!-- Time to call form input -->
      <div class="form-group">
        <label for="time" :class="labelWidth" class="control-label">Time to call</label>
        <div :class="inputWidth">
          <div class="input-group">
            <select class="form-control" name="time" id="time" :value="value.time">
              <option v-for="(hour, index) in getHours()" :value="hour" :key="index">{{hour}}</option>
            </select>
          </div>
        </div>
      </div>

      <ValidationProvider rules="required" name="Contact Number" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <label for="contact_number" :class="labelWidth" class="control-label">Contact number</label>
          <div :class="inputWidth">
            <div class="input-group">
              <input class="form-control" id="contact_number" name="contact_number" placeholder="Contact number" :value="value.contact_number" @input="update('contact_number', $event.target.value)">
            </div>
          </div>
          <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <label for="contact_number" :class="labelWidth" class="control-label">Email</label>
          <div :class="inputWidth">
            <div class="input-group">
              <input class="form-control" id="email" name="email" placeholder="Email" type="email" :value="value.email" @input="update('email', $event.target.value)">
            </div>
          </div>
          <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" name="Contact Preference" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <label :class="labelWidth" class="control-label">Contact preference</label>
          <div class="radio-inline">
            <p>
              <input type="radio" name="contact_preference" id="radio-phone" :value="value.contact_preference !== '' ? value.contact_preference : 'phone'" @input="update('contact_preference', $event.target.value)">
              <label for="radio-phone" class="label--normal">Phone</label>
            </p>
          </div>
          <div class="radio-inline">
              <p>
              <input type="radio" name="contact_preference" id="radio-email" :value="value.contact_preference !== '' ? value.contact_preference : 'email'" @input="update('contact_preference', $event.target.value)">
              <label for="radio-email" class="label--normal">Email</label>
              </p>
            </div>
          <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>
      <!--<div class="form-group" :class="{ 'has-error': errors.has('contact_preference') }">-->
        <!--<label for="email" :class="labelWidth" class="control-label">Contact preference</label>-->
        <!--<div class="radio-inline">-->
          <!--<p>-->
            <!--<input type="radio" name="contact_preference" v-model="value.contact_preference">-->
            <!--Phone-->
          <!--</p>-->
        <!--</div>-->
        <!--<div class="radio-inline">-->
          <!--<p>-->
            <!--<input type="radio" name="contact_preference" v-model="value.contact_preference">-->
            <!--Email-->
          <!--</p>-->
        <!--</div>-->
      <!--</div>-->
      <div :class="submitWidth">
        <loading-button :is-loading="loading" :class="origin === 'dashboard' ? '' : 'btn-block'">Submit</loading-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import LoadingButton from '../utils/LoadingButton';
import FormMixin from '../mixins/FormMixin';

export default {
  name: 'subscription-contact-form',
  props: ['value', 'isLoading', 'origin'],
  mixins: [FormMixin],
  methods: {
    getHours() {
      const hours = [];
      for (let i = 9; i <= 12; i++) {
        hours.push(`${i} am`);
      }
      for (let i = 1; i <= 6; i++) {
        hours.push(`${i} pm`);
      }
      return hours;
    },
  },
  components: {
    LoadingButton,
  },
  computed: {
    loading() {
      return this.isLoading;
    },
    labelWidth() {
      return this.origin === 'dashboard' ? 'col-sm-2' : 'col-sm-4';
    },
    inputWidth() {
      return this.origin === 'dashboard' ? 'col-sm-10' : 'col-sm-8';
    },
    submitWidth() {
      return this.origin === 'dashboard' ? 'col-sm-offset-2 col-sm-10' : '';
    },
  },
};
</script>

<style scoped>
.radio-inline {
  padding-left: 35px;
}
</style>
