var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"content"},[(_vm.modal.isVisible('delete'))?_c('modal',{attrs:{"title":"Delete pupil"},on:{"close":function($event){return _vm.handleCloseModal()}}},[_c('h3',{staticClass:"text-center"},[_vm._v("Remove "+_vm._s(_vm.pupilToDelete.name)+" from "+_vm._s(_vm.school.name)+"?")]),_c('p',{staticClass:"text-center text-danger"},[_vm._v("The pupil account and data will be permanently deleted.")]),_c('hr'),_c('div',{staticClass:"text-center button-locator"},[_c('button',{staticClass:"btn btn-lg btn-yes btn-primary",on:{"click":function($event){return _vm.deletePupil()}}},[_vm._v("Yes")]),_c('button',{staticClass:"btn btn-no btn-lg btn-primary",on:{"click":function($event){return _vm.handleCloseModal()}}},[_vm._v("No")])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"pull-right box-tools"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push('/school/add-readers')}}},[_vm._v("Add Pupils")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"box box-info"},[_c('div',{staticClass:"box-body"},[_c('multiselect',{attrs:{"options":_vm.groups,"hide-selected":true,"multiple":true,"placeholder":"Filter by class"},on:{"select":_vm.addGroupSearchTerms,"remove":_vm.removeGroupSearchTerms},model:{value:(_vm.selectedGroups),callback:function ($$v) {_vm.selectedGroups=$$v},expression:"selectedGroups"}}),_c('div',{staticClass:"dataTables_wrapper form-inline dt-bootstrap",attrs:{"id":"pupils_wrapper"}},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 table-responsive"},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table striped","theme":"auris-admin","columns":_vm.columns,"rows":_vm.pupils,"totalRows":_vm.totalRecords,"mode":"remote","pagination-options":{
                    enabled: true,
                    mode: 'pages',
                    perPage: parseInt(_vm.rowsPerPage),
                    position: 'bottom',
                    perPageDropdown: _vm.rowsPerPageOptions,
                    dropdownAllowAll: false,
                    setCurrentPage: 1,
                    nextLabel: 'next',
                    prevLabel: 'prev',
                    rowsPerPageLabel: 'Rows per page',
                    ofLabel: 'of',
                    pageLabel: 'page', // for 'pages' mode
                    allLabel: 'All',
                  },"search-options":{
                    enabled: true,
                    trigger: 'key-up',
                    skipDiacritics: true,
                    placeholder: 'Search this table',
                  },"isLoading":_vm.isLoading},on:{"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-search":_vm.onSearch},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('a',{staticClass:"u-hover__pointer",on:{"click":function($event){return _vm.goToPupilPage(props.row.id)}}},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]):(props.column.field == 'actions' && _vm.userIsAdmin)?_c('span',[_c('button',{staticClass:"btn btn-link btn-icon-book js-assign",attrs:{"data-name":props.row.name,"data-id":props.row.id},on:{"click":function($event){return _vm.goToAssignBooksPage(props.row.id)}}},[_c('i',{staticClass:"icon icon-book"}),_vm._v(" Manage books")]),_c('button',{staticClass:"btn btn-link btn-icon-trash js-delete",attrs:{"data-name":props.row.name,"data-id":props.row.id},on:{"click":function($event){return _vm.startDeletePupil(props.row)}}},[_c('i',{staticClass:"icon icon-trash"}),_vm._v("Delete")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"loadingContent"},[_c('div',{staticClass:"loadingContent"},[_c('i',{staticClass:"fa fa-refresh fa-spin"})])])],2)],1)])])],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"dataTables_length"})])])}]

export { render, staticRenderFns }