<template>
  <div>
    <slot name="text">
      <p>Congratulations, your new pupils have been imported successfully!</p>
      <p>In order for your pupils to login, they will need their username and password. To help you with this process, we have generated a printable PDF.</p>
      <p>Please download these now. We are unable to recover this information for you at a later date.</p>
    </slot>
    <hr>
    <a :href="links['Logins']" class="btn btn-block file btn-pdf btn-lg pull-left">
      <i class="fa fa-file-pdf-o"></i> <span>Download Pupil Login PDF</span>
    </a>
    <a :href="links['Spreadsheet']" class="btn btn-block file btn-excel btn-lg pull-right">
      <i class="fa fa-file-excel-o"></i> <span>Download Teacher Reference</span>
    </a>
    <div class="clearfix"></div>
    <hr>
    <form class="ui form teacher-form form-horizontal" @submit.prevent="">
      <div class="form-group checkbox" :class="{'has-error': hasFailed}">
        <input id="download" type="checkbox" @click="check()">
        <label for="download">I confirm I have downloaded the files and understand they will not be available to download later.</label>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: 'pupils-links',
  props: {
    hasErrors: {
      default: false,
    },
    links: {
      default: () => [],
    },
  },
  data() {
    return {
      isChecked: true,
      hasFailed: false,
    };
  },
  methods: {
    check() {
      this.isChecked = !!document.getElementById('download').checked; // wtf?
      this.$store.commit('ACCEPT_TERMS_AND_CONDITIONS', this.isChecked); // err...?

      if (!this.isChecked) {
        this.hasFailed = true;
      } else {
        this.hasFailed = false;
      }
    },
  },
  watch: {
    hasErrors(value) {
      this.hasFailed = value;
    },
  },
};
</script>

<style scoped>
  .form-group.checkbox input {
    margin-left:20px;
  }
  .form-group.checkbox label {
    margin-left: 20px;
  }
  .btn-block + .btn-block {
    margin-top: 0;
  }
  .file {
    color: white;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: none;
    padding: 10px;
    display: block;
    width: 49%;
    /*margin: 0 auto;*/
  }
  .file:hover {
    -webkit-box-shadow:inset 0 3px 10px rgba(0,0,0,0.125);
    -moz-box-shadow:inset 0 3px 10px rgba(0,0,0,0.125);
    box-shadow:inset 0 3px 10px rgba(0,0,0,0.125)
  }
  .file.btn-pdf {
    background-color: #E14045;
  }
  .file.btn-excel {
    background-color: #80AF63;
  }
  @media (max-width: 320px) {
    .file {
      width: 100%;
    }
    #modal .modal-body .file.pull-right {
      margin-top: 80px;
    }
  }
</style>
