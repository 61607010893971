<template>
  <!-- Main content -->
  <section class="content invite-teachers">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-warning" v-if="failedInvites">
          <h4>
            <i class="icon icon-warning"></i> Information
          </h4>
          <div v-html="failedInvites"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="box box-info">
          <div class="box-header with-border">
            <h3 class="box-title">Manually add a teacher...</h3>
          </div>
          <div class="box-body">
            <p class="text-muted">Enter the email address and name of each teacher you would like to invite to the school.</p>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form ref="observer" @submit.prevent="handleSubmit(sendInvite)">
                <table class="table emails">
                  <thead>
                  <tr>
                    <th>Email</th>
                    <th>Name</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(emailField, index) in emailFields" :key="index">
                    <td>
                      <ValidationProvider rules="email" name="Email" v-slot="{ errors }">
                        <div class="form-group" :class="{ 'has-error': errors[0] }">
                          <div class="input-group input-group-sm">
                            <div class="input-group-addon"><i class="icon icon-envelope"></i></div>
                            <input type="text"
                                   v-model="emailFields[index].email"
                                   :key="index"
                                   :name="'email'+(index+1)"
                                   :id="'email'+(index+1)"
                                   @keydown="addEmailField(index)"
                                   class="form-control"
                                   placeholder="Email">
                          </div>
                        </div>
                        <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </td>
                    <td>
                      <div class="form-group">
                        <div class="input-group input-group-sm">
                          <div class="input-group-addon"><i class="icon icon-user"></i></div>
                          <input type="text"
                                 v-model="emailFields[index].name"
                                 :key="index"
                                 :name="'name'+(index+1)"
                                 :id="'name'+(index+1)"
                                 class="form-control"
                                 placeholder="Name">
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <loading-button :is-loading="isLoading">Send Invite</loading-button>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="box box-info">
          <div class="box-header with-border">
            <h3 class="box-title">...Or upload a file</h3>
          </div>
          <div class="box-body">
            <p class="text-muted">Fill in our import template and upload it here to invite lot’s of teachers more easily. This is great if you want to circulate the template and ask them to add their own details.</p>
            <drag-drop-file @submit="sendFile" :is-loading="isLoading">
              <slot>
                <a class="btn btn-default pull-right" href="https://s3-eu-west-1.amazonaws.com/auris-api-exports/teachers_example.xlsx" v-if="!isLoading">Download Import Template</a>
                <button disabled="disable" class="btn btn-default pull-right" v-else>Download Import Template</button>
              </slot>
            </drag-drop-file>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import axios from 'axios';
import LoadingButton from '@/components/utils/LoadingButton';
import DragDropFile from '@/components/utils/DragDropFile';

export default {
  data() {
    return {
      emailFields: [{
        email: '',
        name: '',
      }],
      file: null,
      isLoading: false,
      failedInvites: null,
    };
  },
  components: {
    DragDropFile,
    LoadingButton,
    'loading-button': LoadingButton,
  },
  methods: {
    addEmailField(index) {
      if (index + 1 === this.emailFields.length) {
        this.emailFields.push({ email: '', name: '' });
      }
    },
    getEmailsAndNames() {
      const data = { emails: [], names: [] };
      this._.forEach(this.emailFields, (field) => {
        if (!this._.isEmpty(field.email)) {
          data.emails.push(field.email);
          data.names.push(field.name);
        }
      });
      return data;
    },
    sendInvite() {
      this.failedInvites = null;
      this.isLoading = true;
      const enterpriseId = this.$store.state.user.enterprise.id;
      const data = this.getEmailsAndNames();
      this.$http.post(`enterprises/${enterpriseId}/invites`, { email: data.emails, name: data.names })
        .then((response) => {
          if (!this._.isEmpty(response.data.failed)) {
            this.formatFailedInvites(response.data.failed);
          }

          this.isLoading = false;
          if (!this._.isEmpty(response.data.sent)) {
            this.$apiResponse.renderSuccessMessage(response, 'An invitation has been sent to the teachers');
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    formatFailedInvites(failedInvite) {
      let list = '<ul>';
      list += `<li>${failedInvite.email}: ${failedInvite.issue.error}</li>`;
      list += '</ul>';
      this.failedInvites = list;
    },
    sendFile(e) {
      this.failedInvites = null;
      this.isLoading = true;
      const enterpriseId = this.$store.state.user.enterprise.id;
      axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      const data = new FormData();
      data.append('teachers_file', e[0]);
      this.isLoading = true;
      this.$http.post(`enterprises/${enterpriseId}/invites`, data)
        .then((response) => {
          if (!this._.isEmpty(response.data.failed)) {
            this.formatFailedInvites(response.data.failed);
          }

          this.isLoading = false;
          if (!this._.isEmpty(response.data.sent)) {
            this.$apiResponse.renderSuccessMessage(response, 'An invitation has been sent to the teachers');
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$apiResponse.renderErrorMessage(error);
        });
    },
  },
};
</script>
