<template>
  <aside class="main-sidebar">

    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">

      <!-- Sidebar Menu -->
      <sidebar-menu />
      <!-- /.sidebar-menu -->
    </section>
    <!-- /.sidebar -->
  </aside>
</template>
<script>
import SidebarMenu from './SidebarMenu';

export default {
  name: 'Sidebar',
  props: ['displayName'],
  components: { SidebarMenu },
};
</script>
<style>
  .user-panel .image img {
    border-radius: 50%;
  }
</style>
