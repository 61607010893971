export default {
  methods: {
    sendForm(action) {
      this.isLoading = true;
      this.form.post(`contactus/subscriptions/${action}`, this.$store.state.token)
        .then((response) => {
          this.isLoading = false;
          this.showForm = false;
          this.$apiResponse.renderSuccessMessage(response, 'Thanks, we’ve received your request and will attempt to contact you at your requested time.');
        })
        .catch((error) => {
          this.isLoading = false;
          this.$apiResponse.renderErrorMessage(error);
        });
    },
  },
};
