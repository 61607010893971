<template>
  <div class="answer-locator">
      <div v-if="shouldShowContent" class="answer-container">
        <div  class="buttons-container">
          <button :class="{'hidden-visually': !shouldShowPreviousButton}" class="button__left transparent primary-color" @click="$emit('previousButtonClicked', $event)"><i class="icon icon-previous"></i>Previous</button>
          <button :class="{'hidden-visually': !shouldShowVideoButton}" @click="$emit('videoButtonClicked', $event)" class="stand-out"><i class="icon-mask icon-video"></i> <span>Watch video</span></button>
          <button :class="{'hidden-visually': !shouldShowNextButton}" class="button__right transparent primary-color" @click="$emit('nextButtonClicked', $event)">Next<i class="icon__right icon-next"></i></button>
        </div>
        <div class="answer-body">
          <slot>
          </slot>
        </div>
      </div>
      <div v-else class="no-content-text">
        <p>What do you need help with? Click the topics on the left for easy-to-follow tips.</p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'guide-content-viewer',
  props: {
    shouldShowPreviousButton: {
      type: Boolean,
      required: true,
    },
    shouldShowNextButton: {
      type: Boolean,
      required: true,
    },
    shouldShowContent: {
      type: Boolean,
      required: true,
      default: true,
    },
    shouldShowVideoButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  img {
    margin-bottom: 10px;
    max-width: 90%;
  }

  .answer-locator {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #E5E5E5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    min-height: 346px;
  }

  .answer-container {
    background-color: #fff;
    border-radius: 20px;
  }

  .buttons-container {
    background-color: #F2F2F2;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 33px;
    display: flex;
    justify-content: space-between;
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      &.stand-out {
        margin: 3px 0;
        border-radius:50px;
        padding: 0 20px;
        height: 25px;
        i {
          padding-top: 3px;
        }
        span {
          margin-left: 5px;
        }
      }
    }

    button.button__left {
      justify-content: flex-start;
    }

    button.button__right {
      justify-content: flex-end;
    }

    .icon-previous {
      height: 23px;
    }

    .icon-next {
      height: 23px;
    }
  }

  .answer-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .no-content-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    text-decoration: none;
    color: #606265;
    p {
      border-bottom: 1px solid #606265
    }
  }
</style>
