<template>
  <transition name="fade">
    <div class="modal fade in" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel">
      <div class="modal-dialog permissions" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">{{ title }}</h4>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer" v-if="showFooter">
            <slot name="footer"></slot>
            <button type="button" class="btn btn-default" data-dismiss="modal" @click="$emit('close')">Close</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: { required: true },
    showFooter: { default: false, type: Boolean },
  },
};
</script>

<style>
.modal {
  display:block;
}
</style>

<style lang="scss" scoped>
  .modal-header {
    text-transform: uppercase;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .modal-dialog.permissions {
    height: 50%;

    .multiselect__content {
      z-index: 100;

    }
  }
</style>
