<template>
  <ValidationObserver v-slot="{ handleSubmit }" slim>
    <form @submit.prevent="handleSubmit(validateForm)" class="ui form form-horizontal">
      <div class="form-group">
        <ValidationProvider rules="required|min:7|confirmed:confirmPassword" name="Password" v-slot="{ errors }" tag="div"
                            class="form-guest__group" mode="passive">
            <div class="col-sm-10">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-lock"></i></span>
                <input type="password" name="password" id="password" placeholder="New Password" :value="value.password"
                      @input="update('password', $event.target.value)" class="form-control" autocomplete="new-password">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <ValidationProvider vid="confirmPassword" name="Password" v-slot="{ errors }" tag="div"
                            class="form-guest__group" mode="passive">
            <div class="col-sm-10">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-lock"></i></span>
                <input type="password" name="confirm-password" id="confirm-password" placeholder="Confirm Password" v-model="confirmPassword" class="form-control" autocomplete="new-password">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
      </div>
      <div class="text-center">
        <button class="btn btn-primary" group="password" :disabled="isLoading">{{isLoading ? 'Please wait&hellip;' : 'Save new password'}}</button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import FormMixin from '@/components/mixins/FormMixin';

export default {
  name: 'reset-password-form',
  mixins: [FormMixin],
  components: {
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isLoading: {
      default: false,
    },
  },
  data() {
    return {
      confirmPassword: '',
    };
  },
};
</script>
