export default {
  splitAndJoin({ string, splitBy, joinWith }) {
    let stringArray = string.split(splitBy);
    stringArray = stringArray.map((word) => word.toLowerCase());
    return stringArray.join(joinWith);
  },
  formatSecondsAsDuration(value, separator = ', ', precision = 999, format = 'long') {
    if (value === 0) { return '0 seconds'; }

    const formats = {
      short: {
        day: 'day',
        hour: 'hr',
        minute: 'min',
        second: 'sec',
      },
      letter: {
        day: 'd',
        hour: 'h',
        minute: 'm',
        second: 's',
      },
    };

    const mapping = {
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    const durationParts = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, s] of Object.entries(mapping)) {
      const durationUnits = Math.floor(value / s);
      value -= durationUnits * s;

      if (durationUnits > 0) {
        durationParts[key] = durationUnits;
      }
    }

    // precision 999 just means return the full string
    // A reduced precision will return the top most parts of the string only
    const strs = Object.keys(durationParts).slice(0, precision).map((k) => {
      const durationUnits = durationParts[k];
      switch (format) {
        default:
        case 'long':
          return `${durationUnits} ${k}${durationUnits > 1 ? 's' : ''}`;
        case 'short':
          return `${durationUnits} ${formats.short[k]}${durationUnits > 1 ? 's' : ''}`;
        case 'letter':
          return `${durationUnits}${formats.letter[k]}`;
      }
    });

    return strs.join(separator);
  },
};
