<template>
  <div class="book-activity-heatmap" :style="'--cols:'+getCols()" v-tooltip="{
    content: '<div class=\'text-center\'>Visual reference of attention per page, <br /><b>not</b> accuracy or ability.</div>',
    html: true
  }">
    <div :class="'heatmap__value ' + getCssClass(value)" :key="i" v-for="(value, i) in heatmap"></div>
  </div>
</template>

<script>
export default {
  name: 'book-activity-heatmap',
  props: {
    heatmap: {
      required: true,
      type: Array,
    },
  },
  methods: {
    getCols() {
      return this.heatmap.length <= 30 ? 5 : 10;
    },
    getCssClass(value) {
      if (value === 0) {
        return 'heatmap__value__none';
      }
      if (value >= 50 || value === -1) {
        return 'heatmap__value__high';
      }

      return 'heatmap__value__low';
    },
  },
};
</script>

<style scoped lang="scss">
.book-activity-heatmap {
  --cols: 5;
  --cell-size: 8px;
  display: grid;
  grid-template-columns: repeat(var(--cols), var(--cell-size));
  align-self: flex-end;
  gap:2px;
}
.heatmap {
  &__value {
    height: var(--cell-size);
    &__none {
      background-color: lightgray;
    }
    &__low {
      background-color: rgb(116, 225, 116);
    }
    &__high {
      background-color: rgb(48, 156, 48);
    }
  }
}
</style>
