import Authorization from './Authorization';
import IsAuthorized from './directive';

export default {
  install(Vue, options) {
    if (!options.store) {
      throw new Error('Please provide vuex store.');
    }
    Vue.prototype.$authorization = new Authorization(Vue, options.store);
    Vue.directive('if-authorized', IsAuthorized);
  },
};
