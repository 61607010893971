import _ from 'lodash';
import checks from './permissions_checks';

class Authorization {
  constructor(Vue, store) {
    this.store = store;
    this.permissions = {
      add_school: [checks.isNotInSchool],
      edit_school: [checks.isSchoolAdmin],
      add_teacher: [checks.isSchoolAdmin],
      edit_teacher: [checks.isSchoolAdmin],
      delete_teacher: [checks.isSchoolAdmin],
      manage_subscription: [checks.isSchoolAdmin],
      manage_books: [checks.isAnyUser],
    };
  }

  checkAuthorization(action, params = null) {
    if (_.isUndefined(action) || _.isUndefined(this.permissions[action])) {
      return false;
    }

    let canDoIt = false;
    // eslint-disable-next-line consistent-return
    _.forEach(this.permissions[action], (permission) => {
      if (permission(this.store, params) === true) {
        canDoIt = true;
        return true;
      }
    });
    return canDoIt;
  }

  // =============
  // Global method
  // =============
  isAuthorized(action, params = null) {
    return this.checkAuthorization(action, params);
  }
}

export default Authorization;
