import _ from 'lodash';

class APIResponse {
  constructor(Vue, options) {
    this.Vue = Vue;
    this.errorMsg = null;
    this.successMsg = null;
    this.$vm = null;
    this.router = options.router;
  }

  init(vm) {
    this.$vm = vm;
  }

  showHeading(value = true) {
    this.$vm.showHeading = value;
  }

  renderErrorMessage(error, message = null) {
    this.cleanUp();
    if (error.response && error.response.status === 403) {
      this.router.replace('/logout');
    }
    this.errorMsg = APIResponse.pickErrorMessage(error, message);
    this.$vm.errorMsg = this.errorMsg;

    return this.errorMsg;
  }

  renderSuccessMessage(response, message = null) {
    this.cleanUp();

    this.successMsg = APIResponse.pickSuccessMessage(response, message);
    this.$vm.successMsg = this.successMsg;
    return this.successMsg;
  }

  static pickSuccessMessage(response, message = null) {
    if (!_.isEmpty(message)) {
      return message;
    }

    if (!_.isEmpty(response.data.error)) {
      return response.data.error;
    }

    return 'The action was successful';
  }

  static pickErrorMessage(error, message) {
    if (!_.isEmpty(message)) {
      return message;
    }

    if (message) {
      return message;
    }

    if (!_.isEmpty(error.response)) {
      if (error.response.data.error_type === 'validation') {
        let errors = '<ul>';
        _.map(error.response.data.errors, (err) => {
          errors += `<li>${err}</li>`;
        });
        errors += '</ul>';
        return errors;
      }
      try {
        const a = JSON.parse(error.response.data.error);
        const b = a.code.split(':');
        if (b[0] === 'max-readers-reached') {
          switch (b[1]) {
            case 'teacher':
              return `Your current subscription only has capacity for ${b[2]} new readers, contact your school administrator to upgrade your package`;
            case 'admin':
              return `Your current subscription only has capacity for ${b[2]} new readers, click here to upgrade`;
            default:
              return '';
          }
        }
      } catch (e) {
        return error.response.data.error;
      }
    }

    return 'An error occurred';
  }

  nullifyErrorMsg() {
    this.errorMsg = null;
    this.$vm.errorMsg = null;
    window.APIResponseEventBus.errorMsg = null;
  }

  nullifySuccessMsg() {
    this.errorMsg = null;
    this.$vm.errorMsg = null;
    window.APIResponseEventBus.successMsg = null;
  }

  cleanUp() {
    this.nullifyErrorMsg();
    this.nullifySuccessMsg();
  }
}

export default APIResponse;
