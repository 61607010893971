<template>
  <div class="action-messages">
    <div class="alert alert-danger" role="alert" v-if="this.errorMsg">
      <h4 v-if="this.showHeading">
        <i class="icon icon-ban"></i> Information
      </h4>
      <div v-html="errorMsg"></div>
    </div>
    <div class="alert alert-success" role="alert" v-if="this.successMsg">
      <h4 v-if="this.showHeading">
        <i class="icon icon-info"></i> Information
      </h4>
      <div v-html="successMsg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionMessages',
  computed: {
    errorMsg() {
      return window.APIResponseEventBus.errorMsg;
    },
    successMsg() {
      return window.APIResponseEventBus.successMsg;
    },
    showHeading() {
      return window.APIResponseEventBus.showHeading;
    },
  },
  created() {
    this.$apiResponse.cleanUp();
  },
  methods: {
    onRouteUpdate() {
      this.$apiResponse.cleanUp();
      this.$apiResponse.showHeading(true);
    },
  },
  watch: {
    $route: 'onRouteUpdate',
  },
};
</script>
