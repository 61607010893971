<template>
<div class="container-date-range">
  <div class="input-outer">
    <DatePicker
      v-model="dateRange"
      mode="date"
      :is-range="true"
      @input="handleDateRangeUpdated($event)"
      :popover="{ placement: 'bottom-start', visibility: 'click' }"
      :min-date="new Date(2018,0,1)"
      :max-date="new Date()"
      :masks="masks"
    >
      <template v-slot="{inputValue, inputEvents}">
        <input
          :value="inputValue.start"
          v-on="inputEvents.start"
          class="input-inner"
          @click="closeDropdown()"
        />
        <svg
          class="w-4 h-4 mx-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
        <input
          :value="inputValue.end"
          v-on="inputEvents.end"
          class="input-inner input-inner__right"
           @click="closeDropdown()"
        />
      </template>
    </DatePicker>
    <div class="dropdown-toggle">
      <button ref="dropdownMenu" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" type="button" aria-haspopup="true" aria-expanded="false" :id="reference"><i class="fa fa-calendar"></i></button>
      <ul class="dropdown-menu" :aria-labelledby="reference" @click="handleChangeTimePeriod($event)">
        <button type="button" class="dropdown-item">Today</button>
        <button type="button" class="dropdown-item">Yesterday</button>
        <button type="button" class="dropdown-item">Last 7 days</button>
        <button type="button" class="dropdown-item">Last 30 days</button>
        <button type="button" class="dropdown-item">This month</button>
        <button type="button" class="dropdown-item">Last month</button>
        <button type="button" class="dropdown-item">This year</button>
        <button type="button" class="dropdown-item">Last year</button>
      </ul>
    </div>
  </div>

</div>
</template>
<style lang="scss" scoped>
  .w-4 {
    width: 1rem;
  }

  .mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .h-4 {
    height: 1rem;
  }

  .input-outer {
    border: 1px solid #ccc;
    display: flex;
    box-sizing: border-box;
    height: 35px;
    position: relative;
    min-width: 300px;

    span {
      height: 100%;
    }
  }

  .input-inner {
    // border-top: none;
    // border-bottom: none;
    // border-left: none;
    // border-right: 1px solid #ccc;
    border: none;
    width: 10em;
    padding: 5px 10px;
    line-height: 14px;
    font-size: 14px;
    color: rgb(51, 51, 51);
    height:100%;
    outline: none;
  }

  .input-inner__right {
    //border-left: 1px solid #ccc;
    margin-right: 0;
  }

  // .input-inner:focus {
  //   //border-color: #25abc9;
  //   //outline-color: #25abc9;
  // }

  .container {
    width: 100%;
    display: flex;
  }

  .dropdown-toggle {
    border: none;
    border-left: none;
    width: 40px;
    height: 100%;
    background: rgb(236,240,245)
  }

  .btn {
    border: none;
    border-radius: 0;
  }

  .dropdown-menu > button {
    display: inline-block;
    width: 100%;
    border: none;
    background-color: unset;
  }

  .dropdown-menu > button:hover {
    background-color: #25abc9;
    color: white;
    width: 100%;
  }

  .dropdown-menu {
    width: 100%;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .vc-popover-content.direction-bottom.vc-text-gray-900.vc-bg-white.vc-border.vc-border-gray-400.vc-rounded-lg  {
    border-radius: 0;
  }

  .btn.dropdown-toggle:hover {
    color: #25abc9 !important;
  }

  .dropdown-toggle:active {
    outline: none;
  }

  @media only screen and (max-width: 1240px) {
    .input-inner {
      width: 45%;
    }
  }
</style>
<script>
import moment from 'moment';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  name: 'date-range-picker-with-dropdown',
  props: ['reference', 'initialDateRange'],
  components: { DatePicker },
  data() {
    return {
      dateRange: this.initialDateRange,
      masks: {
        input: ['D MMMM YYYY', 'DD/MM/YYYY'],
      },
    };
  },
  methods: {
    handleDateRangeUpdated(e) {
      const dateRangeObject = {
        start: moment(e.start).startOf('day').toDate(),
        end: moment(e.end).endOf('day').toDate(),
      };
      this.$emit('date-range-change', dateRangeObject);
    },
    handleChangeTimePeriod(e) {
      const value = e.target.innerHTML;
      let dateRange = {};
      switch (value) {
        case 'Today':
          dateRange = {
            start: moment().startOf('day'),
            end: moment(),
          };
          break;
        case 'Yesterday':
          dateRange = {
            start: moment().startOf('day').subtract(1, 'days'),
            end: moment().endOf('day').subtract(1, 'days'),
          };
          break;
        case 'Last 7 days':
          dateRange = {
            start: moment().startOf('day').subtract(7, 'days'),
            end: moment(),
          };
          break;
        case 'Last 30 days':
          dateRange = {
            start: moment().startOf('day').subtract(30, 'days'),
            end: moment(),
          };
          break;
        case 'This month':
          dateRange = {
            start: moment().startOf('month'),
            end: moment(),
          };
          break;
        case 'Last month':
          dateRange = {
            start: moment().subtract(1, 'month').startOf('month'),
            end: moment().subtract(1, 'month').endOf('month'),
          };
          break;
        case '3 months':
          dateRange = {
            start: moment().endOf('day').subtract(3, 'months').startOf('month'),
            end: moment(),
          };
          break;
        case '6 months':
          dateRange = {
            start: moment().endOf('day').subtract(6, 'months').startOf('month'),
            end: moment(),
          };
          break;
        case 'This year':
          dateRange = {
            start: moment().startOf('year'),
            end: moment(),
          };
          break;
        case 'Last year':
          dateRange = {
            start: moment().subtract(1, 'year').startOf('year'),
            end: moment().subtract(1, 'year').endOf('year'),
          };
          break;
        default:
      }

      this.dateRange = {
        start: dateRange.start.toDate(),
        end: dateRange.end.toDate(),
      };

      const dateRangeObject = {
        start: this.dateRange.start,
        end: this.dateRange.end,
      };
      this.$emit('date-range-change', dateRangeObject);
    },
    closeDropdown() {
      if (this.$refs.dropdownMenu.parentNode) {
        this.$refs.dropdownMenu.parentNode.classList.remove('open');
      }
    },
  },
};
</script>
