export default {

  data() {
    return {
      contentIsVisible: false,
      currentSection: {
        id: null,
        title: '',
        content: '',
        answerImgPath: '',
      },
    };
  },
  methods: {
    showContent(e) {
      if (e !== this.currentSection) {
        this.currentSection = e;
        this.contentIsVisible = true;
      } else {
        this.contentIsVisible = !this.contentIsVisible;
      }
    },
    add(x, y) {
      return x + y;
    },
    addOne(x) {
      return this.add(x, 1);
    },
    subtractOne(x) {
      return this.add(x, -1);
    },
    changeSection(sections, fn) {
      const newIndex = fn(this.currSectionIndex);
      if (sections[newIndex]) {
        this.currentSection = sections[newIndex];
      } else {
        this.currentSection = sections[this.currSectionIndex];
      }
    },
  },
};
