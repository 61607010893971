<template>
  <section class="content">
        <library-info
          :books="books"
          :currentEntityType="EntityType.CLASS"
          @show-unassign-modal="modal.show('unassign')"
          @update-book-to-remove="updateBookToRemove($event)"
          @book-assignment-changed="updateBookAssignment"
          v-bind:schoolId="schoolId"
          :pupilName="pupilName"
          :groupId="groupId"
        />
  </section>
</template>

<script>
import BooksMixin from '@/components/mixins/BooksMixin';
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import ClassesMixin from '@/components/mixins/ClassesMixin';
import LibraryInfo from '../components/partials/LibraryInfo';

export default {
  name: 'books',
  mixins: [BooksMixin, CurrentUserMixin, ClassesMixin],
  components: {
    LibraryInfo,
  },
  data() {
    return {
      books: [],
      pupilName: '',
    };
  },
  created() {
    this.$store.dispatch('clearCurrentGroup');
  },
  mounted() {
    const school = this.getUserSchool();
    this.schoolId = school.id;
    this.getGroupData();
  },
  methods: {
    getGroupData() {
      this.getGroup(this.schoolId, this.groupId)
        .then((group) => {
          this.group = group;
          if (!this.$authorization.isAuthorized('manage_books', { group })) {
            this.$router.back();
          }
          this.$store.dispatch('setCurrentGroup', group);
        }).then(() => this.getBooks({
          entityType: this.EntityType.CLASS,
          schoolId: this.schoolId,
          groupId: this.groupId,
        })).then((data) => {
          this.books = data.books;
          if (data.meta && data.meta.reader_name) {
            this.pupilName = data.meta.reader_name;
          }
        });
    },
  },
  computed: {
    groupId() {
      return Number.parseInt(this.$route.params.classId, 10);
    },
  },
};
</script>
