<template>
  <div class="flexbox-grid-container u-margin-top-65">
    <grid-item
      title="FAQs"
      iconClass="icon-faq-dark"
      :hasViewAll="false"
      :withArrow="contentIsVisible"
    >
      <ul class="links-list">
        <li v-for="faq in faqs" :key="faq.id" @click="showContent(faq)" :class="{active: faq.id === currentSection.id}"><span>{{faq.title}}</span></li>
      </ul>
    </grid-item>

    <grid-item
      :title="currentSection.title"
      iconClass="icon-faq-content"
      :hasViewAll="false"
      headerClass="fb-grid-box-header__green"
      :isVisible="contentIsVisible"
    >
      <guide-content-viewer
        :shouldShowPreviousButton="previousSectionExists"
        :shouldShowNextButton="nextSectionExists"
        @previousButtonClicked="changeSection(faqs, subtractOne)"
        @nextButtonClicked="changeSection(faqs, addOne)"
        :shouldShowContent="true"
      >
        <img v-if="currentSection.contentIsImg" :src="currentSection.content" alt="">
        <div class="answer-body__html-locator" v-if="currentSection.contentIsHtml" v-html="currentSection.content"></div>
      </guide-content-viewer>
    </grid-item>
  </div>
</template>

<script>
import GridItem from '@/components/partials/GridItem';
import GuideContentViewer from '@/components/partials/GuideContentViewer';
import UserGuideMixin from '@/components/mixins/UserGuideMixin';
import answer1 from '@/assets/img/faq-questions/question-1.png';
import answer2 from '@/assets/img/faq-questions/question-2.png';
import answer3 from '@/assets/img/faq-questions/question-3.png';
// import answer5 from '@/assets/img/faq-questions/question-5.png';
// import answer6 from '@/assets/img/faq-questions/question-6.png';
import answer7 from '@/assets/img/faq-questions/question-7.png';

export default {
  name: 'FAQs',
  components: { GridItem, GuideContentViewer },
  mixins: [UserGuideMixin],
  data() {
    return {
      faqs: [
        {
          id: 1,
          title: "How do I delete a pupil's account?",
          content: answer1,
          contentIsHtml: false,
          contentIsImg: true,
        },
        {
          id: 2,
          title: "How can I reset a pupil's password",
          content: answer2,
          contentIsHtml: false,
          contentIsImg: true,
        },
        {
          id: 3,
          title: 'How do I merge two profiles together?',
          content: answer3,
          contentIsHtml: false,
          contentIsImg: true,
        },
        // {
        //   id: 5,
        //   title: "My child has a school account but I'd like to sign up for Fonetti, How do I sign up?",
        //   content: answer5,
        //   contentIsHtml: false,
        //   contentIsImg: true,
        // },
        // {
        //   id: 6,
        //   title: "Can teachers view a pupil's reading stats for books they read on their Fonetti subscriptions?",
        //   content: answer6,
        //   contentIsHtml: false,
        //   contentIsImg: true,
        // },
        {
          id: 7,
          title: 'I have made a typo when adding a pupil to Fonetti (Schools), can I edit their name?',
          content: answer7,
          contentIsImg: true,
        },
        {
          id: 8,
          title: 'What are the technical requirements for Fonetti?',
          contentIsImg: false,
          contentIsHtml: true,
          content: '<p>You can download our technical specifications by clicking the button below.</p><a href="/files/fonetti-technical-requirements.pdf" download="Fonetti Technical Specifications" class="button-link"><button data-v-708b1f00="" class="download light-green"><span data-v-708b1f00="" class="button-span">Download technical specifications</span><i data-v-708b1f00="" class="icon icon-download"></i></button></a>',
        },
      ],
    };
  },
  computed: {
    previousSectionExists() {
      return !!this.faqs[this.currSectionIndex - 1];
    },
    nextSectionExists() {
      return !!this.faqs[this.currSectionIndex + 1];
    },
    currSectionIndex() {
      return this.faqs.indexOf(this.currentSection);
    },
  },
  mounted() {
    if (this.$route.params.faqId) {
      let { faqId } = this.$route.params;
      faqId = parseInt(faqId, 10);
      const [faq] = this.faqs.filter((f) => f.id === faqId);
      this.showContent(faq);
    }
  },
};
</script>

<style lang="scss" scoped>
  img {
    margin-bottom: 10px;
    max-width: 90%;
  }

  .answer-locator {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #E5E5E5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .answer-body__html-locator {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 200px;
  }
</style>
