<template>
  <div class="overlay" v-if="loading">
    <i class="fa fa-refresh fa-spin"></i>
  </div>
</template>

<style lang="scss" scoped>
  .overlay {
    position: absolute;
    z-index: 10;
    background-color: rgba(0,0,0,0.1);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .fa-spin.fa {
    font-size: 30px;
    margin-top: calc(350px / 2 - 15px);
    margin-left: calc(50% - 15px);
  }
</style>

<script>
export default {
  name: 'LoadingState',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    loading() {
      return this.isLoading;
    },
  },
};
</script>
